import { useState, useRef, useCallback } from "react";
const NEAREST_URL =
  "https://developer.nrel.gov/api/alt-fuel-stations/v1/nearby-route.json";

const fetchData = (distance, additionalParams, signal) => {
  let params = {
    fuel_type: "ELEC",
    format: "JSON",
    // NREL API doesn't handle preflight CORS requests properly, so we have to
    // include the API key as a GET query param instead of as a header
    limit: "all",
    distance,
    ...additionalParams
  };
  let url = new URL(NEAREST_URL);
  url.search = new URLSearchParams({
    api_key: process.env.REACT_APP_NREL_API_KEY
  });

  let body = new URLSearchParams(params);

  return window.fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    },
    body,
    signal
  });
};

const useChargingStations = () => {
  const [loading, setLoading] = useState(false);
  const [chargingStations, setChargingStations] = useState([]);
  const [error, setError] = useState("");

  const abortControllerRef = useRef(null);

  const fetchChargingStations = useCallback(async ({ route, distance  = 15 }) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    if (!route) return;

    try {
      abortControllerRef.current = new window.AbortController();
      setLoading(true);

      const response = await fetchData(
        distance,
        {
          route
        },
        abortControllerRef.current.signal
      );
      const json = await response.json();

      abortControllerRef.current = null;

      setLoading(false);
      if (json) {
        setChargingStations(json.fuel_stations);
      }
    } catch (error) {
      setLoading(false);

      if (error.name !== "AbortError") {
        setError(error);
      }
      abortControllerRef.current = null;
    }
  }, [abortControllerRef, setError, setLoading]);

  return {
    error,
    chargingStations,
    fetchChargingStations,
    loading
  };
};

export default useChargingStations;
