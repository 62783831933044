import React from 'react';

const GeneralEmergency = ({ fill = "#005179", ...rest }) => (
  <svg width="66px" height="66px" viewBox="0 0 66 66" version="1.1" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <title>cebdc8b7-517c-4195-8948-9992745d4d8b@1.00x</title>
    <desc>Created with sketchtool.</desc>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="general_emergency" fill={fill} fillRule="nonzero">
        <path d="M33,0 L33.5449686,0.0044286 C51.49452,0.29634 66,14.982 66,33 C66,51.2 51.2,66 33,66 L33,66 C14.8,66 0,51.2 0,33 C0,14.8 14.8,0 33,0 Z M33,4.5 L32.5298799,4.5038301 C17.04528,4.75629 4.5,17.457 4.5,33 C4.5,48.7 17.3,61.5 33,61.5 L33,61.5 C48.7,61.5 61.5,48.7 61.4,33 C61.4,28.8 60.5,24.8 58.9,21.2 C54.4,11.3 44.5,4.5 33,4.5 Z M33.1,6.2 C33.7461538,6.2 34.2218935,6.4556213 34.6058716,7.04551661 L34.7,7.2 L56.1,44.7 C56.7758621,45.8586207 56.2398335,47.3901308 54.67193,47.4943745 L54.5,47.5 L11.5,47.5 C9.95517241,47.5 9.34256837,46.0084423 9.93221124,44.8254828 L10,44.7 L31.5,7.2 C31.9,6.5 32.3,6.2 33.1,6.2 Z M33,37.9 C31.5,37.9 30.3,39.1 30.3,40.6 C30.3,42.1 31.5,43.3 33,43.3 C34.5,43.3 35.8,42.1 35.7,40.6 C35.7,39.1 34.5,37.9 33,37.9 Z M31.3,18.8 C29.8,19.5 28.7,21.1 28.6,22.7 C28.5,23.6 28.8,24.5 29.1,25.4 C29.4,26.1 29.6,26.9 29.8,27.6 C30.2,29.1 30.6,30.6 31.1,32 C31.3,32.7 31.4,33.6 32.1,34.1 C32.8,34.6 33.9,34.4 34.4,33.7 C34.5666667,33.5333333 34.6638889,33.3666667 34.749537,33.1421296 L34.8,33 L35.115625,31.890625 C35.44375,30.79375 35.8,29.725 36.1,28.6 C36.5,27.1 37,25.6 37.4,24.1 C37.9,22.4 37.3,20.5 36.1,19.4 C34.7,18.3 32.8,18.1 31.3,18.8 Z" id="Combined-Shape"></path>
      </g>
    </g>
  </svg>
);

export default GeneralEmergency;
