import React from "react";
import PropTypes from "prop-types";
import "./MapLegend.css";

const MapLegend = ({ children }) => <div className="MapLegend">{children}</div>;

export default MapLegend;

MapLegend.propTypes = {
  children: PropTypes.node
};
