let stationFilter = [
"Subaru",
"Porsche",
"Dodge",
"Lincoln",
"Fiat",
"Cadillac",
"Acura",
"Jeep",
"Land Rover",
"Chevrolet",
"Ford",
"BMW",
"Ram",
"Audi",
"Honda",
"Mercedes",
"GMC",
"Lexus",
"Chrysler",
"Alfa Romeo",
"Nissan",
"Mazda",
"Infiniti",
"Mitsubishi",
"Jaguar",
"Toyota",
"Volkswagen",
"Tesla",
"Buick",
"Genesis",
"Hyundai",
"Volvo",
"Mini",
"MINI",
]

export default stationFilter;