import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import MapStyles from "./MapStyles";
import ChargingStationsMapMarker from "../ChargingStationsMapMarker/ChargingStationsMapMarker";
import isReactSnapping from "../../utils/predicates/isReactSnapping";
import VisibilitySensor from "react-visibility-sensor";
import staticImage from "../../assets/images/kia-static-full.png";
import staticZoomIcon from "../../assets/images/kia-static-plus-icon.png";

const DEFAULT_CENTER = {
  lat: 39.50683430370299,
  lng: -95.16240565,
};
const INITIAL_ZOOM = 4;
const K_HOVER_DISTANCE = 30;
const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const BaseGoogleMapReact = ({
  center,
  children,
  onHoverOnStation,
  onHoverOffStation,
  onClickMarker,
  chargingStations,
  selectedStation,
  showStationsByPowerLevel,
  publicIcon,
  highPowerIcon,
  defaultIcon,
  publicIconSelected,
  highPowerIconSelected,
  defaultIconSelected,
  customizeMarkOnHover,
  hoveredStation,
  showRealMap,
  onInteraction,
  ...rest
}) => {
  const [wrapper, setWrapper] = useState();
  useEffect(() => {
    const wrp = document.getElementsByClassName(".TabbedMaps")[0];
    if (wrp) {
      setWrapper(wrp);
    }
  }, []);

  const onInteractionWrapper = () => {
    onInteraction();

    setTimeout(() => {
      const mapWrapper = document.getElementsByClassName("gm-style")[0];

      if (mapWrapper) {
        mapWrapper.style.display = "block";
      }
    }, 500);
  };

  const hoverProps = {
    ...(onHoverOnStation && {
      onChildMouseEnter: (key, childProps) => {
        onHoverOnStation(childProps.station.id);
      },
    }),
    ...(onHoverOffStation && {
      onChildMouseLeave: onHoverOffStation,
    }),
  };

  if (isReactSnapping()) return null;

  return (
    <VisibilitySensor key="google-maps" containment={wrapper}>
      {() => {
        return !showRealMap ? (
          <div>
            <img
              src={staticZoomIcon}
              onClick={onInteractionWrapper}
              alt="Google Maps Zoom Static"
              style={{
                cursor: "pointer",
                zIndex: 10,
                position: "absolute",
                bottom: 25,
                right: 10,
              }}
            />
            <img
              src={staticImage}
              alt="Google Maps Static"
              style={{ cursor: "grab" }}
              onWheel={onInteractionWrapper}
              onDrag={onInteractionWrapper}
            />
          </div>
        ) : (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: API_KEY,
              libraries: "places",
            }}
            defaultCenter={DEFAULT_CENTER}
            center={center}
            defaultZoom={INITIAL_ZOOM}
            hoverDistance={K_HOVER_DISTANCE}
            options={{ styles: MapStyles, fullscreenControl: false }}
            yesIWantToUseGoogleMapApiInternals
            {...hoverProps}
            {...rest}
          >
            {(chargingStations || []).map((station, i) => (
              <ChargingStationsMapMarker
                key={station.id}
                station={station}
                selectedStation={selectedStation}
                hoveredStation={hoveredStation}
                lat={station.latitude}
                lng={station.longitude}
                onClick={onClickMarker}
                showStationsByPowerLevel={showStationsByPowerLevel}
                publicIcon={publicIcon}
                highPowerIcon={highPowerIcon}
                defaultIcon={defaultIcon}
                publicIconSelected={publicIconSelected}
                highPowerIconSelected={highPowerIconSelected}
                defaultIconSelected={defaultIconSelected}
                customizeMarkOnHover={customizeMarkOnHover}
              />
            ))}
            {children}
          </GoogleMapReact>
        );
      }}
    </VisibilitySensor>
  );
};

BaseGoogleMapReact.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  children: PropTypes.node,
  onClickMarker: PropTypes.func,
  onHoverOnStation: PropTypes.func,
  onHoverOffStation: PropTypes.func,
  chargingStations: PropTypes.array,
  selectedStation: PropTypes.object,
  showStationsByPowerLevel: PropTypes.bool,
  customizeMarkOnHover: PropTypes.func,
};

BaseGoogleMapReact.defaultProps = {
  chargingStations: [],
  center: DEFAULT_CENTER,
  onClickMarker: () => {},
  showStationsByPowerLevel: true,
  customizeMarkOnHover: () => {},
};

export default BaseGoogleMapReact;
