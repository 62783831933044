import React from "react";
import { Link } from "react-router-dom";
import PageLayout from "../PageLayout/PageLayout";

const FourOhFour = ({ ...rest }) => {
  return (
    <PageLayout
      title="Page Not Found - KIA EV Savings Calculator"
      description="Page Not Found - KIA EV Savings Calculator"
      {...rest}
    >
      <section className="container-fluid" style={{ minHeight: "500px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="SimpleCopyPageHeader">
                <p className="h3">Page Not Found</p>
                <p className="">
                  This page does not exist. If you arrived here by error, please
                  go back to the <Link to="/">homepage</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default FourOhFour;
