import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

import useMapBounds from "../../hooks/useMapBounds";
import useSelectedStation from "../../hooks/useSelectedStation";
import useChargingStations from "../../hooks/useChargingStations";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";
import useMappedZipcode from "../../hooks/useMappedZipcode";
import useGeoJsonUrls from "../../hooks/useGeojsonUrls";

import MapControlPanel from "../MapControlPanel/MapControlPanel";
import BaseGoogleMapReact from "../BaseGoogleMapReact/BaseGoogleMapReact";
import ChargingStationsList from "../ChargingStationsList/ChargingStationsList";
import ZipcodeInputWithButton from "../ZipcodeInputWithButton/ZipcodeInputWithButton";
import MapLegend from "../MapLegend/MapLegend";
import PspsEventLegend from "../PspsEventLegend/PspsEventLegend";
import Checkbox from "../shared/InputElements/Checkbox";

import isSuperChargingStation from "../../utils/predicates/isSuperChargingStation";
import setupGoogleMapsAutocomplete from "../../utils/setupGoogleMapsAutocomplete";

import "./ChargingStationsMap.css";

const ChargingStationsMap = ({
  chargingStationsFilterFn,
  isVisible,
  pspsEvents = [],
  canIgnoreLowPowerStations,
  showStationsByPowerLevel,
  showStationsList,
  highPowerLegendTitle,
  highPowerLegendLabel,
  otherTypesLegendTitle,
  otherTypesLegendLabel,
  publicLegendTitle,
  publicLegendLabel,
  publicIcon,
  highPowerIcon,
  defaultIcon,
  publicIconSelected,
  highPowerIconSelected,
  defaultIconSelected,
  searchBtnText,
  controlPanelTitle,
  zipcodeLabel,
  zipcodePlaceholder,
  customizeMarkOnHover,
  initialZoomLevel = 4,
  btnStyles,
  footerText,
  showListBtnStyle,
  showLegendCard,
  chargingListStyle,
  chargingListCardStyle,
  allowAddress,
}) => {
  const userPrefs = useContext(UserPrefsContext);

  const [startAddress, setStartAddress] = useState();
  const [showRealMap, setShowRealMap] = useState(false);
  const [currentZipCode, setCurrentZipCode] = useState(null);

  const [isIgnoringLowPowerStations, setIsIgnoringLowPowerStations] = useState(
    false
  );

  const autocompleteStartLocationRef = useRef(null);

  const {
    bounds,
    registerMapBoundsListeners,
    filterWithinBounds,
    center,
  } = useMapBounds();

  const { chargingStations, fetchChargingStations } = useChargingStations();

  const [selectedStation, selectStation] = useSelectedStation(chargingStations);

  const [hoveredStation, setHoveredStation] = useState(false);

  const {
    zipcode: geocodedZipcode,
    loading: isFetchingZipcode,
    error: zipcodeError,
    fetchZipcode,
    registerMappedZipcodeListeners,
  } = useMappedZipcode({
    zoom: initialZoomLevel,
  });

  const handleFetchZipcode = (zip) => {
    if (!showRealMap) {
      setCurrentZipCode(zip);
      setShowRealMap(true);
    } else {
      fetchZipcode(zip, 15);
    }
  };

  const registerGeojsonUrlMap = useGeoJsonUrls(
    pspsEvents.flatMap((event) => event.file_urls)
  );

  const contextSetter = userPrefs.set;
  useEffect(
    () => {
      if (geocodedZipcode) {
        contextSetter({ zipcode: geocodedZipcode });
      }
    },
    [geocodedZipcode, contextSetter]
  );

  useDeepCompareEffect(
    () => {
      if (!startAddress && !currentZipCode) return;

      const { lat: latitude, lng: longitude } = center;

      fetchChargingStations({ latitude, longitude }, bounds);
    },
    [center, bounds]
  );

  const onCompleteStartLocation = () => {
    const address = autocompleteStartLocationRef.current.getPlace()
      .formatted_address;
    setStartAddress(address);
  };

  const handleGoogleMapsApiLoaded = ({ map }) => {
    registerMapBoundsListeners(map);
    registerMappedZipcodeListeners(map, currentZipCode);
    fetchZipcode(
      currentZipCode || "",
      15,
      currentZipCode === undefined ? null : map
    );
    registerGeojsonUrlMap(map);

    if (allowAddress) {
      setupGoogleMapsAutocomplete(
        autocompleteStartLocationRef,
        "charging-stations-map-location",
        onCompleteStartLocation
      );
    }
  };

  const visibleChargingStations = filterWithinBounds(chargingStations).filter(
    (station) =>
      isIgnoringLowPowerStations ? isSuperChargingStation(station) : true
  );

  const filteredChargingStations = chargingStationsFilterFn
    ? visibleChargingStations.filter(chargingStationsFilterFn)
    : visibleChargingStations;

  const handleStationSelect = (station) => {
    setHoveredStation(station);
  };

  return (
    <div className="row ChargingStationsMap">
      <div className="col-lg-3 left-column">
        <MapControlPanel
          selectedStation={selectedStation}
          chargingStations={filteredChargingStations}
          showStationsByPowerLevel={showStationsByPowerLevel}
          highPowerLegendTitle={highPowerLegendTitle}
          highPowerLegendLabel={highPowerLegendLabel}
          otherTypesLegendTitle={otherTypesLegendTitle}
          otherTypesLegendLabel={otherTypesLegendLabel}
          publicLegendTitle={publicLegendTitle}
          publicLegendLabel={publicLegendLabel}
          publicIcon={publicIcon}
          highPowerIcon={highPowerIcon}
          defaultIcon={defaultIcon}
          alwaysShowLegend
        >
          {controlPanelTitle && <p className="title">{controlPanelTitle}</p>}

          <ZipcodeInputWithButton
            zipcodePlaceholder={zipcodePlaceholder}
            customId="charging-stations-map-location"
            buttonText={searchBtnText || "Search Charging Stations"}
            error={zipcodeError}
            isLoading={isFetchingZipcode}
            onSubmitZipcode={handleFetchZipcode}
            zipcode={startAddress}
            zipcodeLabel={zipcodeLabel}
            btnStyles={btnStyles}
            allowAddress={allowAddress}
            onClick={() => setShowRealMap(true)}
          />
          {canIgnoreLowPowerStations && (
            <div className="form-group">
              <Checkbox
                id="show-only-supercharging-stations-route"
                value={isIgnoringLowPowerStations}
                label="High Power Stations Only"
                handler={(e) =>
                  setIsIgnoringLowPowerStations((currentValue) => {
                    return !currentValue;
                  })
                }
              />
            </div>
          )}
        </MapControlPanel>
      </div>
      <div className="col-lg-9">
        <div className="map-container">
          <MapLegend>{pspsEvents.length > 0 && <PspsEventLegend />}</MapLegend>
          {isVisible && (
            <BaseGoogleMapReact
              onGoogleApiLoaded={handleGoogleMapsApiLoaded}
              onHoverOnStation={handleStationSelect}
              onClickMarker={selectStation}
              chargingStations={filteredChargingStations}
              selectedStation={selectedStation}
              hoveredStation={hoveredStation}
              showStationsByPowerLevel={showStationsByPowerLevel}
              publicIcon={publicIcon}
              highPowerIcon={highPowerIcon}
              defaultIcon={defaultIcon}
              publicIconSelected={publicIconSelected}
              highPowerIconSelected={highPowerIconSelected}
              defaultIconSelected={defaultIconSelected}
              customizeMarkOnHover={customizeMarkOnHover}
              zoom={initialZoomLevel}
              showRealMap={showRealMap}
              onInteraction={() => {
                setShowRealMap(true);
              }}
            />
          )}
        </div>
      </div>

      <div className="col-lg-12">
        <ChargingStationsList
          footerText={footerText}
          btnStyle={showListBtnStyle}
          showLegendCard={showLegendCard}
          chargingStations={filteredChargingStations}
          publicIcon={publicIcon}
          highPowerIcon={highPowerIcon}
          defaultIcon={defaultIcon}
          chargingListStyle={chargingListStyle}
          chargingListCardStyle={chargingListCardStyle}
          showStationsList={showStationsList}
        />
      </div>
    </div>
  );
};

ChargingStationsMap.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  isVisible: PropTypes.bool,
  pspsEvents: PropTypes.array,
  showStationsByPowerLevel: PropTypes.bool,
  showStationsList: PropTypes.bool,
  highPowerLegendTitle: PropTypes.string,
  highPowerLegendLabel: PropTypes.string,
  otherTypesLegendTitle: PropTypes.string,
  otherTypesLegendLabel: PropTypes.string,
  publicLegendTitle: PropTypes.string,
  publicLegendLabel: PropTypes.string,
  publicIcon: PropTypes.string,
  highPowerIcon: PropTypes.string,
  defaultIcon: PropTypes.string,
  searchBtnText: PropTypes.string,
  controlPanelTitle: PropTypes.string,
  zipcodeLabel: PropTypes.string,
  customizeMarkOnHover: PropTypes.func,
  initialZoomLevel: PropTypes.number,
  btnStyles: PropTypes.object,
};

ChargingStationsMap.defaultProps = {
  isVisible: true,
  showStationsByPowerLevel: false,
  showStationsList: true,
  customizeMarkOnHover: () => {},
};

export default ChargingStationsMap;
