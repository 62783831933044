import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import uniq from "lodash/uniq";

import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

import BaseGoogleMapReact from "../BaseGoogleMapReact/BaseGoogleMapReact";
import MapControlPanel from "../MapControlPanel/MapControlPanel";
import SelectedDealerCard from "../SelectedDealerCard/SelectedDealerCard";
import DealersList from "../DealersList/DealersList";

import useMapBounds from "../../hooks/useMapBounds";
import useDealers from "../../hooks/useDealers";
import useSelectedDealer from "../../hooks/useSelectedDealer";
import useMappedZipcode from "../../hooks/useMappedZipcode";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect.js";
import useOemImages from "../../hooks/useOemImages.js";

import DealerPin from "./../../assets/images/icons/dealer-pin.png";
import ZipcodeInputWithButton from "../ZipcodeInputWithButton/ZipcodeInputWithButton";

import haversineDistance from "../../utils/Helpers/haversineDistance";
import Select from "../shared/InputElements/Select";
import useGeoJsonUrls from "../../hooks/useGeojsonUrls";
import MapLegend from "../MapLegend/MapLegend";
import PspsEventLegend from "../PspsEventLegend/PspsEventLegend";

import "./DealersMap.css";

const DealerMarker = ({ isSelected, onClick }) => {
  const styles = {
    width: "25px",
    ...(isSelected && {
      filter: "brightness(85%)"
    })
  };

  const placeStyle = {
    display: "block",
    position: "absolute",
    transform: "translate(-50%, -100%)"
  };

  return (
    <span style={placeStyle} onClick={onClick}>
      <img src={DealerPin} style={styles} alt="" />
    </span>
  );
};

DealerMarker.propTypes = {
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
};

DealerMarker.defaultProps = {
  isSelected: false,
  onClick: () => {}
};

const SelectSpecificDealer = ({ dealers, onChange, selectedDealerId }) => {
  const sortedDealers = dealers.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div className="form-group">
      <Select value={selectedDealerId} onChange={onChange} label="Dealers">
        <option value="">All Dealers</option>
        {sortedDealers.map(dealer => {
          return (
            <option
              key={dealer.dealership_handle}
              value={dealer.dealership_handle}
            >
              {dealer.name}
            </option>
          );
        })}
      </Select>
    </div>
  );
};

SelectSpecificDealer.propTypes = {
  dealers: PropTypes.array,
  onChange: PropTypes.func,
  selectedDealerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const SelectOem = ({ dealers, onChange, selectedOem }) => {
  const oems = dealers.map(d => d.oem);
  const uniqueOems = uniq(oems);
  const sortedOems = uniqueOems.sort((a, b) => a.localeCompare(b));

  if (sortedOems.length < 2) {
    return null;
  }

  return (
    <div className="form-group">
      <Select value={selectedOem} onChange={onChange} label="Brands">
        <option value="">All Brands</option>
        {sortedOems.map(oem => {
          return (
            <option key={oem} value={oem}>
              {oem}
            </option>
          );
        })}
      </Select>
    </div>
  );
};

SelectOem.propTypes = {
  dealers: PropTypes.array,
  onChange: PropTypes.func,
  selectedOem: PropTypes.string
};

const DealersMap = ({ isVisible, oems, pspsEvents = [] }) => {
  const userPrefs = useContext(UserPrefsContext);

  const [specificDealerId, setSpecificDealerId] = useState();
  const [selectedOem, setSelectedOem] = useState();

  const {
    bounds,
    center,
    filterWithinBounds,
    registerMapBoundsListeners
  } = useMapBounds();

  const { dealers, fetchDealers } = useDealers();

  const [selectedDealer, selectDealer, deselectDealers] = useSelectedDealer(
    dealers
  );
  const { images: oemImages } = useOemImages();

  const {
    zipcode: geocodedZipcode,
    loading: isFetchingZipcode,
    error: zipcodeError,
    fetchZipcode,
    registerMappedZipcodeListeners
  } = useMappedZipcode({
    zoom: 10
  });

  const contextSetter = userPrefs.set;
  useEffect(
    () => {
      if (geocodedZipcode) {
        contextSetter({ zipcode: geocodedZipcode });
      }
    },
    [geocodedZipcode, contextSetter]
  );

  useEffect(
    () => {
      if (specificDealerId) {
        selectDealer(specificDealerId);
      }
    },
    [selectDealer, specificDealerId]
  );

  useDeepCompareEffect(
    () => {
      const { lat, lng } = center;
      const coordinates = [
        { lat: bounds.top, lng: bounds.left },
        { lat: bounds.top, lng: bounds.right },
        { lat: bounds.bottom, lng: bounds.left },
        { lat: bounds.bottom, lng: bounds.right }
      ];

      const distancesInMeters = coordinates.map(
        coordinate => haversineDistance(center, coordinate, false) * 1000
      );

      const maxDistanceInMeters = Math.max(...distancesInMeters);

      fetchDealers({
        lat,
        lng,
        radiusInMi: maxDistanceInMeters / 1609
      });
    },
    [center, bounds, haversineDistance]
  );

  const registerGeojsonUrlMap = useGeoJsonUrls(
    pspsEvents.flatMap(event => event.file_urls)
  );

  const handleGoogleMapsApiLoaded = ({ map, maps }) => {
    registerMapBoundsListeners(map);
    registerMappedZipcodeListeners(map, userPrefs.get("zipcode"));
    registerGeojsonUrlMap(map);
  };

  const handleChildMouseEnter = (key, childProps) => {
    selectDealer(childProps.dealership_handle);
  };

  const handleChangeSpecificDealer = e => {
    deselectDealers();
    setSelectedOem("");
    setSpecificDealerId(e.target.value);
  };

  const handleChangeOem = e => {
    deselectDealers();
    setSpecificDealerId("");
    setSelectedOem(e.target.value);
  };

  const uppercaseOems = oems.map(oem => oem.toUpperCase());
  const visibleDealers = filterWithinBounds(dealers).filter(dealer => {
    return oems.length > 0
      ? uppercaseOems.includes(dealer.oem.toUpperCase())
      : true;
  });

  const filteredDealers = visibleDealers
    .filter(dealer => {
      return specificDealerId
        ? specificDealerId === dealer.dealership_handle
        : true;
    })
    .filter(dealer => {
      return selectedOem ? dealer.oem === selectedOem : true;
    });

  const selectedOemImage = oemImages.find(image => {
    if (!selectedDealer || !selectedDealer.oem) return null;
    return image.name.toUpperCase() === selectedDealer.oem.toUpperCase();
  });

  return (
    <div className="row DealersMap">
      <div className="col-sm-3 left-column">
        <MapControlPanel>
          <div className="controls">
            <ZipcodeInputWithButton
              buttonText="Search Qualified Dealers"
              error={zipcodeError}
              isLoading={isFetchingZipcode}
              onSubmitZipcode={fetchZipcode}
              zipcode={userPrefs.get("zipcode")}
            />

            <hr />

            <SelectOem
              dealers={visibleDealers}
              selectedOem={selectedOem}
              onChange={handleChangeOem}
            />

            <SelectSpecificDealer
              dealers={visibleDealers}
              selectedDealerId={specificDealerId}
              onChange={handleChangeSpecificDealer}
            />
          </div>
          {selectedDealer && (
            <div className="selected-dealer-container">
              <SelectedDealerCard
                oemImage={selectedOemImage}
                {...selectedDealer}
              />
            </div>
          )}
        </MapControlPanel>
      </div>
      <div className="col-sm-9">
        <div className="map-container">
          <MapLegend>{pspsEvents.length > 0 && <PspsEventLegend />}</MapLegend>
          {isVisible && (
            <BaseGoogleMapReact
              onGoogleApiLoaded={handleGoogleMapsApiLoaded}
              onChildMouseEnter={handleChildMouseEnter}
            >
              {filteredDealers.map(dealer => {
                return (
                  <DealerMarker
                    key={dealer.dealership_handle}
                    lat={dealer.latitude}
                    lng={dealer.longitude}
                    dealership_handle={dealer.dealership_handle}
                    isSelected={
                      dealer.dealership_handle ===
                      (selectedDealer || {}).dealership_handle
                    }
                    onClick={deselectDealers}
                  />
                );
              })}
            </BaseGoogleMapReact>
          )}
        </div>
      </div>

      <div className="col-sm-12">
        <DealersList dealers={filteredDealers} oemImages={oemImages} />
      </div>
    </div>
  );
};

export default DealersMap;

DealersMap.propTypes = {
  isVisible: PropTypes.bool,
  oems: PropTypes.array,
  pspsEvents: PropTypes.array
};

DealersMap.defaultProps = {
  isVisible: true,
  oems: []
};
