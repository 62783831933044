import { useState, useEffect } from "react";

let url = `${process.env.REACT_APP_EV_INFO_API_HOST}/assets/oems`;
let token = process.env.REACT_APP_EV_INFO_TKN;

const useOemImages = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        setLoading(true);
        const response = await window.fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        setImages(data.assets);
        setLoading(false);
      } catch (e) {
        setError(e);
      }
    };

    fetchImages();
  }, []);

  return {
    loading,
    error,
    images,
  };
};

export default useOemImages;
