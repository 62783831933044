import { useState } from "react";

const getDealer = (dealers, id) =>
  (dealers || []).find(dealer => dealer.dealership_handle === id);

const useSelectedDealer = (dealers, initialDealerId) => {
  const [selectedDealer, setSelectedDealer] = useState(
    getDealer(dealers, initialDealerId)
  );
  const selectDealer = dealerId => {
    setSelectedDealer(getDealer(dealers, dealerId));
  };

  const deselectDealers = () => setSelectedDealer(null);

  return [selectedDealer, selectDealer, deselectDealers];
};

export default useSelectedDealer;
