import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import BaseGoogleMapReact from "../BaseGoogleMapReact/BaseGoogleMapReact";
import MapControlPanel from "../MapControlPanel/MapControlPanel";

import { FormatCarName } from "../../utils/Helpers/Format";
import isSuperChargingStation from "../../utils/predicates/isSuperChargingStation";
import setupGoogleMapsAutocomplete from "../../utils/setupGoogleMapsAutocomplete.js";
import GaTracker from "../../utils/GaTracker/GaTracker";
import useMapBounds from "../../hooks/useMapBounds";
import useSelectedStation from "../../hooks/useSelectedStation";
import useChargingStations from "../../hooks/useChargingStations";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";

import ChargingStationsList from "../ChargingStationsList/ChargingStationsList";
import Checkbox from "../shared/InputElements/Checkbox.js";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Select from "../shared/InputElements/Select";
import useGeoJsonUrls from "../../hooks/useGeojsonUrls";
import PspsEventLegend from "../PspsEventLegend/PspsEventLegend";
import MapLegend from "../MapLegend/MapLegend";

import "./TravelRadiusMap.css";

const INITIAL_CENTER = {
  lat: 37.7916858,
  lng: -122.397855,
};

const TravelRadiusMap = ({
  chargingStationsFilterFn,
  electricVehicles,
  isVisible,
  canIgnoreLowPowerStations = true,
  pspsEvents = [],
  showStationsByPowerLevel,
  showStationsList,
  highPowerLegendTitle,
  highPowerLegendLabel,
  otherTypesLegendTitle,
  otherTypesLegendLabel,
  publicLegendTitle,
  publicLegendLabel,
  publicIcon,
  highPowerIcon,
  defaultIcon,
  publicIconSelected,
  highPowerIconSelected,
  defaultIconSelected,
  controlPanelTitle,
  customizeMarkOnHover,
  btnStyles,
  showLegendCard,
  chargingListStyle,
  chargingListCardStyle,
  searchBtnlabel,
  showListBtnStyle,
  footerText,
  hideStations,
  zipcodeLabel,
  onlyElectricRange,
  onlyTotalRange,
  electricRangeLegend,
  totalRangeLegend,
  zipcodePlaceholder,
}) => {
  const [address, setAddress] = useState();
  const [showRealMap, setShowRealMap] = useState(false);
  const [geocodeError, setGeocodeError] = useState();
  const [filteredEV, setFilteredEV] = useState();
  const [isGeocoding, setIsGeocoding] = useState(false);
  const [isIgnoringLowPowerStations, setIsIgnoringLowPowerStations] = useState(
    canIgnoreLowPowerStations
  );
  // Tracking as state (instead of calculating from currentVehicle) because
  // we want to reset/update this when fetching the zipcode data and not when
  // we select a vehicle.
  // Stored as an object (instead of separate values) to reduce re-renders
  const [{ currentElectricRange, currentTotalRange }, setRangeInMi] = useState({
    currentElectricRange: null,
    currentTotalRange: null,
  });

  const mapRef = useRef(null);
  const geocoderRef = useRef(null);
  const electricCircleRef = useRef(null);
  const totalCircleRef = useRef(null);
  const autocompleteRef = useRef(null);

  const [vehicleIdForTravelRadius, setVehicleIdForTravelRadius] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const {
    bounds,
    center,
    registerMapBoundsListeners,
    filterWithinBounds,
  } = useMapBounds();

  const {
    chargingStations,
    fetchChargingStations,
    error: chargingStationsError,
  } = useChargingStations();

  const [selectedStation, selectStation, deselectStations] = useSelectedStation(
    chargingStations
  );

  const registerGeojsonUrlMap = useGeoJsonUrls(
    pspsEvents.flatMap((event) => event.file_urls)
  );

  useEffect(
    () => {
      if (electricVehicles && electricVehicles.length) {
        setFilteredEV(
          electricVehicles.filter(
            (ev) =>
              ev.make === "Kia" &&
              (ev.fuel_type === "BEV" || ev.fuel_type === "PHEV")
          )
        );
      }
    },
    [electricVehicles]
  );

  useEffect(
    () => {
      if ((filteredEV || []).length === 1) {
        setVehicleIdForTravelRadius(filteredEV[0].vehicle_id);
      } else if (filteredEV && !vehicleIdForTravelRadius) {
        const randomIndex = Math.floor(Math.random() * filteredEV.length);
        setVehicleIdForTravelRadius(filteredEV[randomIndex].vehicle_id);
      }
    },
    [filteredEV, vehicleIdForTravelRadius]
  );

  useDeepCompareEffect(
    () => {
      const { lat: latitude, lng: longitude } = center;
      fetchChargingStations({ latitude, longitude }, bounds);
    },
    [center, bounds]
  );

  const currentSelectedVehicle = (electricVehicles || []).find(
    (v) => v.vehicle_id === selectedVehicle
  );

  const currentVehicle = (electricVehicles || []).find(
    (v) => v.vehicle_id === vehicleIdForTravelRadius
  );

  const fetchAddress = ({ isTrackingEvent = true }) => {
    if (!address) return;

    setSelectedVehicle(vehicleIdForTravelRadius);
    setIsGeocoding(true);
    setGeocodeError(undefined);
    setRangeInMi({});

    if (isTrackingEvent) {
      GaTracker.trackEvent({
        category: "Maps",
        action: "Searched Travel Radius",
        label: FormatCarName(currentVehicle),
      });
    }

    geocoderRef.current.geocode(
      {
        address,
      },
      (results, status) => {
        if (status === "OK") {
          renderMapWithCircles(results[0]);
        } else {
          setGeocodeError("There was an error geocoding this address");
        }
        setIsGeocoding(false);
      }
    );
  };

  const onCompleteAddress = () => {
    setAddress(autocompleteRef.current.getPlace().formatted_address);
  };

  const handleGoogleMapsApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    /* eslint-disable no-undef */
    geocoderRef.current = new google.maps.Geocoder();

    if (!onlyElectricRange) {
      totalCircleRef.current = new google.maps.Circle({
        strokeColor: "#07679b",
        strokeOpacity: 0.6,
        strokeWeight: 1,
        fillColor: "#07679b",
        fillOpacity: 0.1,
        center: INITIAL_CENTER,
        radius: 0,
      });
    }

    electricCircleRef.current = new google.maps.Circle({
      strokeColor: "#07679b",
      strokeOpacity: 0.6,
      strokeWeight: 1,
      fillColor: "#07679b",
      fillOpacity: 0.5,
      center: INITIAL_CENTER,
      radius: 0,
    });
    /* eslint-enable no-undef */

    setupGoogleMapsAutocomplete(
      autocompleteRef,
      "travel-radius-map-address-input",
      onCompleteAddress
    );
    registerMapBoundsListeners(map);
    fetchAddress({ isTrackingEvent: false });

    registerGeojsonUrlMap(map);
  };

  const renderCircle = ({ circle, lat, lng, radiusInMeters, map }) => {
    circle.setCenter({
      lat,
      lng,
    });

    circle.setRadius(radiusInMeters);
    circle.setMap(map);
  };

  const renderMapWithCircles = (result) => {
    const {
      geometry: {
        location: { lat, lng },
      },
    } = result;
    const electricRadiusInMeters = currentVehicle
      ? currentVehicle.electric_range_in_mi * 1609
      : 0;
    const totalRadiusInMeters = currentVehicle
      ? currentVehicle.total_range_in_mi * 1609
      : 0;

    const { current: electricCircle } = electricCircleRef;
    const { current: totalCircle } = totalCircleRef;

    if (!onlyTotalRange) {
      renderCircle({
        circle: electricCircle,
        lat: lat(),
        lng: lng(),
        radiusInMeters: electricRadiusInMeters,
        map: mapRef.current,
      });
    }

    const isTotalRangeLarger = totalRadiusInMeters >= electricRadiusInMeters;

    if (!onlyElectricRange) {
      renderCircle({
        circle: totalCircle,
        lat: lat(),
        lng: lng(),
        radiusInMeters: totalRadiusInMeters,
        map: isTotalRangeLarger ? mapRef.current : null,
      });
    }

    mapRef.current.fitBounds(totalCircle.getBounds());
    setRangeInMi({
      currentElectricRange: currentVehicle.electric_range_in_mi,
      currentTotalRange: currentVehicle.total_range_in_mi,
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && isSubmittingAllowed()) {
      fetchAddress({ isTrackingEvent: false });
    }
  };

  const visibleChargingStations = filterWithinBounds(chargingStations).filter(
    (station) =>
      isIgnoringLowPowerStations ? isSuperChargingStation(station) : true
  );
  const filteredChargingStations = chargingStationsFilterFn
    ? visibleChargingStations.filter(chargingStationsFilterFn)
    : visibleChargingStations;

  const isVehicleSelectable = (electricVehicles || []).length > 1;

  const isSubmittingAllowed = () =>
    vehicleIdForTravelRadius && address && !isGeocoding;

  return !electricVehicles ? (
    <LoadingSpinner />
  ) : (
    <div className="row TravelRadiusMap">
      <div className="col-lg-3 left-column">
        <MapControlPanel
          selectedStation={selectedStation}
          chargingStations={hideStations ? [] : filteredChargingStations}
          showStationsByPowerLevel={showStationsByPowerLevel}
          highPowerLegendTitle={highPowerLegendTitle}
          highPowerLegendLabel={highPowerLegendLabel}
          otherTypesLegendTitle={otherTypesLegendTitle}
          otherTypesLegendLabel={otherTypesLegendLabel}
          publicLegendTitle={publicLegendTitle}
          publicLegendLabel={publicLegendLabel}
          publicIcon={publicIcon}
          highPowerIcon={highPowerIcon}
          defaultIcon={defaultIcon}
        >
          {controlPanelTitle && <p className="title">{controlPanelTitle}</p>}
          <div className="form-group">
            <label htmlFor="travel-radius-map-address-input">
              {zipcodeLabel || "Zip Code or Address"}
            </label>
            <input
              id="travel-radius-map-address-input"
              type="text"
              className="form-control"
              onChange={(e) => setAddress(e.target.value)}
              onKeyPress={handleKeyPress}
              value={address}
              placeholder={zipcodePlaceholder || "Address"}
              disabled={isGeocoding}
              onClick={() => setShowRealMap(true)}
            />
          </div>
          {isVehicleSelectable && (
            <div className="form-group">
              <Select
                value={vehicleIdForTravelRadius}
                onChange={(e) => setVehicleIdForTravelRadius(e.target.value)}
                onKeyPress={handleKeyPress}
                disabled={isGeocoding}
                label="Vehicle"
              >
                <option value="">Select a vehicle</option>
                {(filteredEV || []).map((electricVehicle) => {
                  return (
                    <option
                      value={electricVehicle.vehicle_id}
                      key={electricVehicle.vehicle_id}
                    >
                      {FormatCarName(electricVehicle)}
                    </option>
                  );
                })}
              </Select>
            </div>
          )}
          <div className="form-group">
            <button
              style={btnStyles}
              className="btn-kia"
              type="button"
              onClick={fetchAddress}
              disabled={!isSubmittingAllowed()}
            >
              {searchBtnlabel || "View travel radius"}
            </button>
          </div>
          {chargingStationsError && (
            <p className="text-danger">
              There was an error fetching charging stations. Please refresh the
              page and try again.
            </p>
          )}
          {geocodeError && (
            <p className="text-danger">
              We could not find that address. Please revise your search and try
              again.
            </p>
          )}
          {canIgnoreLowPowerStations && (
            <div className="form-group">
              <Checkbox
                id="show-only-supercharging-stations-radius"
                value={isIgnoringLowPowerStations}
                label="High Power Stations Only"
                handler={(e) =>
                  setIsIgnoringLowPowerStations((currentValue) => {
                    GaTracker.trackEvent({
                      category: "Maps",
                      action: "Toggled 'High Power Stations Only'",
                      label: currentValue ? "All Stations" : "High Power Only",
                    });
                    return !currentValue;
                  })
                }
              />
            </div>
          )}
        </MapControlPanel>
      </div>
      <div className="col-lg-9">
        <div className="map-container">
          <MapLegend>
            {(currentSelectedVehicle?.fuel_type === "BEV" ||
              (!onlyTotalRange && currentElectricRange)) && (
              <div className="d-flex mb-1">
                <span className="range-icon range-icon-electric" />
                <span>
                  {currentElectricRange}{" "}
                  {electricRangeLegend || "miles electric range, full battery*"}
                </span>
              </div>
            )}
            {!onlyElectricRange &&
              currentTotalRange &&
              currentSelectedVehicle?.fuel_type !== "BEV" && (
                <div className="d-flex mb-1">
                  <span className="range-icon range-icon-total" />
                  <span>
                    {currentTotalRange}{" "}
                    {totalRangeLegend || "miles total range, full tank of gas"}
                  </span>
                </div>
              )}
            {pspsEvents.length > 0 && <PspsEventLegend />}
          </MapLegend>
          {isVisible && (
            <BaseGoogleMapReact
              onGoogleApiLoaded={handleGoogleMapsApiLoaded}
              onHoverOnStation={selectStation}
              onClickMarker={deselectStations}
              chargingStations={hideStations ? [] : filteredChargingStations}
              selectedStation={selectedStation}
              showStationsByPowerLevel={showStationsByPowerLevel}
              publicIcon={publicIcon}
              highPowerIcon={highPowerIcon}
              defaultIcon={defaultIcon}
              publicIconSelected={publicIconSelected}
              highPowerIconSelected={highPowerIconSelected}
              defaultIconSelected={defaultIconSelected}
              customizeMarkOnHover={customizeMarkOnHover}
              showRealMap={showRealMap}
              onInteraction={() => {
                setShowRealMap(true);
              }}
            />
          )}
        </div>
      </div>

      <div className="col-lg-12">
        <ChargingStationsList
          footerText={footerText}
          btnStyle={showListBtnStyle}
          showLegendCard={showLegendCard}
          chargingStations={filteredChargingStations}
          publicIcon={publicIcon}
          highPowerIcon={highPowerIcon}
          defaultIcon={defaultIcon}
          chargingListStyle={chargingListStyle}
          chargingListCardStyle={chargingListCardStyle}
          showStationsList={showStationsList}
        />
      </div>
    </div>
  );
};

export default TravelRadiusMap;

TravelRadiusMap.propTypes = {
  canIgnoreLowPowerStations: PropTypes.bool,
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  isVisible: PropTypes.bool,
  pspsEvents: PropTypes.array,
  showStationsByPowerLevel: PropTypes.bool,
  showStationsList: PropTypes.bool,
  highPowerLegendTitle: PropTypes.string,
  highPowerLegendLabel: PropTypes.string,
  otherTypesLegendTitle: PropTypes.string,
  otherTypesLegendLabel: PropTypes.string,
  publicLegendTitle: PropTypes.string,
  publicLegendLabel: PropTypes.string,
  publicIcon: PropTypes.string,
  highPowerIcon: PropTypes.string,
  defaultIcon: PropTypes.string,
  searchBtnText: PropTypes.string,
  controlPanelTitle: PropTypes.string,
  zipcodeLabel: PropTypes.string,
  customizeMarkOnHover: PropTypes.func,
  initialZoomLevel: PropTypes.number,
  btnStyles: PropTypes.object,
  showLegendCard: PropTypes.bool,
  searchBtnlabel: PropTypes.string,
  hideStations: PropTypes.bool,
};

TravelRadiusMap.defaultProps = {
  isVisible: true,
};
