const ALL_STATIONS_TAB_NAME = "Stations Near Me";
const ALL_STATIONS_TAB_URL = "/";
export const ALL_STATIONS_TAB_ID = "tabbed-maps-all-stations-tab";

const ROUTE_MAP_TAB_NAME = "Map My Route";
const ROUTE_MAP_TAB_URL = "/map-my-route";
export const ROUTE_MAP_TAB_ID = "tabbed-maps-route-tab";

const RANGE_FROM_FULL_CHARGE_TAB_NAME = "Full Travel Range";
const RANGE_FROM_FULL_CHARGE_TAB_URL = "/travel-range";
export const RANGE_FROM_FULL_CHARGE_TAB_ID =
  "tabbed-maps-range-from-full-charge-tab";

export const DEALER_CATALOG_TAB_ID = "tabbed-maps-dealer-catalog-tab";

export const MAP_TABS = [
  {
    id: ALL_STATIONS_TAB_ID,
    name: ALL_STATIONS_TAB_NAME,
    url: ALL_STATIONS_TAB_URL
  },
  {
    id: ROUTE_MAP_TAB_ID,
    name: ROUTE_MAP_TAB_NAME,
    url: ROUTE_MAP_TAB_URL
  },
  {
    id: RANGE_FROM_FULL_CHARGE_TAB_ID,
    name: RANGE_FROM_FULL_CHARGE_TAB_NAME,
    url: RANGE_FROM_FULL_CHARGE_TAB_URL
  },
];
