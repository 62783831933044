import { useState, useRef } from "react";

const useMappedZipcode = ({ zoom }) => {
  const [zipcode, setZipcode] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const mapRef = useRef();
  const geocoderRef = useRef();

  const fetchZipcode = async (newZipcode, newZoom, map = null) => {
    setError(undefined);

    let geo = null;

    if (!geocoderRef.current) {
      /* eslint-disable no-undef */
      geo = new google.maps.Geocoder();
      geocoderRef.current = geo;
    }

    if ((map == null || !!newZipcode) && (geo || geocoderRef.current)) {
      setLoading(true);
      (geo || geocoderRef.current).geocode(
        {
          address: newZipcode,
        },
        (results, status) => {
          if (status === "OK") {
            let isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(newZipcode);

            setZipcode(isValidZip ? newZipcode : zipcode);

            (map || mapRef.current).setCenter(results[0].geometry.location);
            (map || mapRef.current).setZoom(newZoom || zoom || 15);
          } else {
            setError(
              "We could not find that zipcode. Please check it and try again"
            );
          }

          setLoading(false);
        }
      );
    }

    const mapWrapper = document.getElementsByClassName("gm-style")[0];

    if (mapWrapper) {
      mapWrapper.style.display = "block";
    }
  };

  const registerMappedZipcodeListeners = (map, initialZipcode) => {
    /* eslint-enable no-undef */
    // fetchZipcode(initialZipcode, 15, map);

    /* eslint-disable no-undef */
    mapRef.current = map;
  };

  return {
    zipcode,
    fetchZipcode,
    loading,
    error,
    registerMappedZipcodeListeners,
  };
};

export default useMappedZipcode;
