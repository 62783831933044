import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";


import ChargingPinHighPower from "./../../assets/images/icons/charger-pin-high-power.svg";
import ChargingPinDefault from "./../../assets/images/icons/charger-pin-default.svg";
import ChargingPinPublic from "./../../assets/images/icons/charger-pin-default.svg";

// NOTE: refactored this into its own component to mitigate this:
// https://github.com/google-map-react/google-map-react/issues/583
const ChargingStationsMapMarker = ({
  station,
  ignoreSuperchargerStations,
  selectedStation,
  onClick,
  showStationsByPowerLevel,
  publicIcon,
  highPowerIcon,
  defaultIcon,
  publicIconSelected,
  highPowerIconSelected,
  defaultIconSelected,
  customizeMarkOnHover,
  hoveredStation
}) => {
  const imgRef = useRef()

  let iconType =
    Number(station.ev_dc_fast_num) && !ignoreSuperchargerStations
      ? "highPower"
      : station.access_code === "public"
        ? "public"
        : "default";

  let imageStyle = {
    width: "25px"
  };

  if ((hoveredStation && hoveredStation === station.id) || (selectedStation && selectedStation.id === station.id)) {
    if (customizeMarkOnHover) {
      customizeMarkOnHover(imageStyle)
    }
  }

  const placeStyle = {
    display: "block",
    position: "absolute",
    transform: "translate(-50%, -100%)"
  };

  const iconSrc = (type, showStationsByPowerLevel) => {
    return showStationsByPowerLevel ? getIconByPower(type) : getDefaultIcon(type)  
  };

  const getDefaultIcon = useCallback((type) => {
    switch (type) {
      case "public":
        return publicIcon || ChargingPinPublic;
      case "highPower":
        return highPowerIcon || ChargingPinHighPower;
      case "private":
      default:
        return defaultIcon || ChargingPinDefault;
    }
  }, [defaultIcon, highPowerIcon, publicIcon])

  const getIconByPower = useCallback((type) => {
    switch (type) {
      case "highPower":
        return highPowerIcon || ChargingPinHighPower;
      case "private":
      default:
        return defaultIcon || ChargingPinDefault;
    }
  }, [defaultIcon, highPowerIcon])

  const getDefaultIconSelected = useCallback((type) => {
    switch (type) {
      case "public":
        return publicIconSelected || ChargingPinPublic;
      case "highPower":
        return highPowerIconSelected || ChargingPinHighPower;
      case "private":
      default:
        return defaultIconSelected || ChargingPinDefault;
    }
  }, [publicIconSelected, highPowerIconSelected, defaultIconSelected])

  const getIconByPowerSelected = useCallback((type) => {
    switch (type) {
      case "highPower":
        return highPowerIconSelected || ChargingPinHighPower;
      case "private":
      default:
        return defaultIconSelected || ChargingPinDefault;
    }
  }, [highPowerIconSelected, defaultIconSelected])

  useEffect(() => {
    if ((hoveredStation && hoveredStation === station.id) || (selectedStation && selectedStation.id === station.id)) {
      imgRef.current.src = showStationsByPowerLevel ? getIconByPowerSelected(iconType) : getDefaultIconSelected(iconType)  
    } else {
      imgRef.current.src = showStationsByPowerLevel ? getIconByPower(iconType) : getDefaultIcon(iconType) 

    }
  }, [hoveredStation, selectedStation, getDefaultIcon, getDefaultIconSelected, getIconByPower, getIconByPowerSelected, iconType, showStationsByPowerLevel, station.id])

  return (
    <span station={station} style={placeStyle} onClick={() => onClick(station.id)}>
      <img ref={imgRef} src={iconSrc(iconType, showStationsByPowerLevel)} style={imageStyle} alt="" />
    </span>
  );
};

ChargingStationsMapMarker.propTypes = {
  station: PropTypes.shape({
    ev_dc_fast_num: PropTypes.number,
    access_code: PropTypes.string
  }),
  ignoreSuperchargerStations: PropTypes.bool,
  selectedStation: PropTypes.object,
  onClick: PropTypes.func,
  showStationsByPowerLevel: PropTypes.bool,
  customizeMarkOnHover: PropTypes.func
};

ChargingStationsMapMarker.defaultProps = {
  station: {},
  ignoreSuperchargerStations: false,
  selectedStation: null,
  onClick: () => {},
  showStationsByPowerLevel: true,
  customizeMarkOnHover: () => {}
};

export default ChargingStationsMapMarker;
