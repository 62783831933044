/* eslint-disable handle-callback-err */
import React, { Component } from "react";
import "url-search-params-polyfill";
import { Switch, Route, withRouter } from "react-router-dom";
import "./PgeFrontend.css";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";

// Pages
import MapPage from "../../pages/MapPage/MapPage";
import FourOhFour from "../../pages/FourOhFour/FourOhFour";

// Services
import fetchElectricVehicles from "../../services/fetchElectricVehicles";

// Utilities
import Uuid from "../../utils/Uuid/Uuid";
import sortEVs from "../../functions/vehicle/Sort/sortEVs";
import { loadState, persistState } from "../../utils/LocalStorage/LocalStorage";
import loadUserPrefs from "../../context/UserPrefs/loadUserPrefs";
import getUserPref from "../../context/UserPrefs/getUserPref";
import USER_PREF_PRESETS from "../../context/UserPrefs/USER_PREF_PRESETS";
import { MAP_TABS } from "../../constants/mapTabs";
import GaTracker from "../../utils/GaTracker/GaTracker";


import isReactSnapping from '../../utils/predicates/isReactSnapping';

class PgeFrontend extends Component {
  constructor(props) {
    super(props);

    const existingState = loadState() || {};
    const savedPrefs =
      existingState && existingState.userPreferences
        ? existingState.userPreferences
        : {};

    if (!savedPrefs.vehicleFormFactorFilters)
      savedPrefs.vehicleFormFactorFilters = {};
    if (!savedPrefs.vehicleFuelTypeFilters)
      savedPrefs.vehicleFuelTypeFilters = {};

    this.state = {
      // User Tracking:
      uuid: existingState.uuid || Uuid(),

      // From EV_INFO:
      electricVehicles: null,
      incentives: null,
      articles: null,
      gasVehicles: null,
      pspsEvents: [],
      pspsEventsError: undefined,

      // Dynamically set:
      userLocation: null,
      userLocationNotFound: false,
      zipcodeUpdating: true,
      isFetchingIncentives: false,

      // UI:
      incentivePrefsModalIsOpen: false,
      rateLaunchPageIsOpen: true,

      // User Preferences
      userPreferences: loadUserPrefs(savedPrefs)
    };
    // Last resort to ensure that the user has a UUID
    if (!this.state.uuid) this.state.uuid = Uuid();

    this.loadElectricVehicleData = this.loadElectricVehicleData.bind(this);
    this.updateUserPreferences = this.updateUserPreferences.bind(this);
  }

  componentDidMount() {   
    this.loadElectricVehicleData();
    this.loadZipcodeData();

    if (!isReactSnapping()) {
      GaTracker.initialize();
      const page = this.props.location.pathname + this.props.location.search;
      GaTracker.trackPage(page, { userId: this.state.uuid });
    }
  }

  // TODO: this should be cleaner
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode
    ) {
      this.loadElectricVehicleData();
      this.loadZipcodeData();
    }

    persistState(this.state);

    const currentPage = prevProps.location.pathname + prevProps.location.search;
    const nextPage = this.props.location.pathname + this.props.location.search;

    if (currentPage !== nextPage) {
      GaTracker.trackPage(nextPage, { userId: this.state.uuid });
    }
  }

  // Calling Unified API
  async loadElectricVehicleData() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      tax_filing_type: getUserPref(
        "taxFilingStatus",
        this.state.userPreferences
      ),
      household_size: getUserPref("householdSize", this.state.userPreferences),
      household_income: getUserPref(
        "householdIncome",
        this.state.userPreferences
      ),
      turn_in_clunker: getUserPref(
        "canTurnInClunker",
        this.state.userPreferences
      )
        ? true
        : null
    };
    // Remove null params
    Object.keys(params).forEach(
      key => params[key] == null && delete params[key]
    );

    const getValidVehicleId = electricVehicles => {
      if (!electricVehicles) return null;
      const currentVehicleId = this.state.userPreferences.vehicleIdForRateComp;

      const randomVehicleId =
        electricVehicles[Math.floor(electricVehicles.length * Math.random())]
          .vehicle_id;

      return currentVehicleId &&
        electricVehicles.find(ev => ev.vehicle_id === currentVehicleId)
        ? currentVehicleId
        : randomVehicleId;
    };

    try {
      const electricVehicles = await fetchElectricVehicles(params);
      if (!electricVehicles) return;
      this.setState({
        electricVehicles: sortEVs(electricVehicles, "alphabetical", "asc"),
        userPreferences: {
          ...this.state.userPreferences,
          vehicleIdForRateComp: getValidVehicleId(electricVehicles)
        }
      });
    } catch (e) {
      // TODO: handle errors here
    }
  }

  loadZipcodeData() {
    let zipcode = getUserPref("zipcode", this.state.userPreferences);

    let url = new URL(
      `${process.env.REACT_APP_EV_INFO_API_HOST}/location?postcode=${zipcode}`
    );

    window
      .fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`
        }
      })
      .then(response => {
        if (response.status === 404) {
          this.setState({
            userLocationNotFound: true,
            zipcodeUpdating: false
          });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        if (data.location) {
          this.setState({
            userLocation: data.location,
            userLocationNotFound: false,
            zipcodeUpdating: false
          });
          this.incentivePrefsModalToggle(false);
        }
      })
      .catch(error => {
        // Do nothing
      });
  }

  updateUserPreferences(newPrefs) {
    let prefs = Object.assign({}, this.state.userPreferences, newPrefs);
    let newState = {
      userPreferences: prefs
    };
    if (
      newPrefs.zipcode &&
      this.state.userPreferences.zipcode !== newPrefs.zipcode
    )
      newState.zipcodeUpdating = true;
    this.setState(newState);
  }

  incentivePrefsModalToggle(override) {
    if (typeof override === "boolean") {
      this.setState({ incentivePrefsModalIsOpen: override });
    } else {
      this.setState({
        incentivePrefsModalIsOpen: !this.state.incentivePrefsModalIsOpen
      });
    }
  }

  render() {
    const {
      electricVehicles,
      incentivePrefsModalIsOpen,
      pspsEvents,
      pspsEventsError,
      rateLaunchPageIsOpen,
      userLocationNotFound,
      userPreferences,
      uuid,
      zipcodeUpdating
    } = this.state;


    const userPrefs = {
      get: key => getUserPref(key, userPreferences),
      getPreset: key => USER_PREF_PRESETS[key],
      set: this.updateUserPreferences,
      zipcodeIsNotFound: userLocationNotFound,
      zipcodeIsUpdating: zipcodeUpdating,
      incentivePrefsModalIsOpen: incentivePrefsModalIsOpen,
      incentivePrefsModalToggle: this.incentivePrefsModalToggle,
      rateLaunchPageIsOpen: rateLaunchPageIsOpen,
      rateLaunchPageToggle: () => this.setState({ rateLaunchPageIsOpen: false })
    };

    const commonProps = {
      uuid,
    };

    return (
      <UserPrefsContext.Provider value={userPrefs}>
        <Switch>
          {MAP_TABS.map(tab => {
            return (
              <Route
                exact
                key={tab.id}
                path={tab.url !== "/charging-stations" ? tab.url : '/'}
                render={props => {
                  return (
                    <MapPage
                      {...props}
                      tabId={tab.id}
                      electricVehicles={electricVehicles}
                      pspsEvents={pspsEvents}
                      pspsEventsError={pspsEventsError}
                      {...commonProps}
                    />
                  );
                }}
              />
            );
          })}

          <Route component={FourOhFour} />
        </Switch>
      </UserPrefsContext.Provider>
    );
  }
}

export default withRouter(PgeFrontend);
