import React from "react";
import GeneralEmergency from "../../assets/images/general_emergency";

const PspsErrorAlert = () => {
  return (
    <>
      <GeneralEmergency height="1.5rem" width="1.5rem" fill="#ffffff" className="mr-2 d-inline-block" />
      <div className="font-weight-bold d-inline-block">
        A PSPS event may be happening. Please see the{" "}
        <a
          href="https://kiaalerts.alerts.kia.com/"
          target="_blank"
          rel="noopener noreferrer"
        >

          official PSPS page
        </a>{" "}
        to verify.
      </div>
    </>
  );
};

export default PspsErrorAlert;
