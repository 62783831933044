import React from "react";
import PropTypes from "prop-types";
import Meta from "@bellawatt/meta-tags";

import "./PageLayout.css";

import Main from "./Main/Main";

const PageLayout = ({
  children,
  title = "KIA EV Savings Calculator",
  description = "KIA EV Savings Calculator",
  metaImage,
  structuredData,
}) => {

  return (
    <div className="PageLayout">
      <Meta>
        <Meta.Title>{title}</Meta.Title>
        <Meta.Description>{description}</Meta.Description>
        <Meta.Image src={metaImage} />
        {structuredData && (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        )}
      </Meta>
      <Main>
        <span id="main-content-id" tabIndex="-1" />
        <div className="main-content-container">
          <div className="page-specific-content-container">{children}</div>         
        </div>
      </Main>
    </div>
  );
};

export default PageLayout;

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  metaImage: PropTypes.string,
  structuredData: PropTypes.object,
}
