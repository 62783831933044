import React from "react";
import PropTypes from "prop-types";
import PageLayout from "../PageLayout/PageLayout";
import TabbedMaps from "../../components/TabbedMaps/TabbedMaps";
import useMapTabs from "../../hooks/useMapTabs";
import PspsErrorAlert from '../../containers/PgeFrontend/PspsErrorAlert';
import PspsEventsAlert from '../../containers/PgeFrontend/PspsEventsAlert';

import "./MapPage.css";

const MapPageAlert = ({ pspsEvents, pspsEventsError }) => {
  if (pspsEventsError) return <PspsErrorAlert />;
  if (pspsEvents.length > 0) return <PspsEventsAlert />;
  return null;
};

const MapPage = ({
  electricVehicles,
  tabId,
  history,
  pspsEvents = [],
  pspsEventsError,
  ...rest
}) => {
  const { activeTabId, toggle, findTab } = useMapTabs(tabId);

  const toggleWithHistory = newTabId => {
    const newTab = findTab(newTabId);
    history.push(newTab.url);
    toggle(newTab.id);
  };

  const hasAlert = pspsEventsError || pspsEvents.length > 0;

  return (
    <PageLayout
      page="map"
      title="EV Charging Stations - Kia"
      description="Find electric car charging stations in US to plug in your electric car (EV). Enter your zip code and find EV charging stations nearby."
      alert={hasAlert && (
        <MapPageAlert pspsEvents={pspsEvents} pspsEventsError={pspsEventsError} />
      )}
      {...rest}
    >
      <h1 className="hide-offscreen">Map</h1>
        <div className="container">
          <TabbedMaps
            electricVehicles={electricVehicles}
            activeTabId={activeTabId}
            toggle={toggleWithHistory}
            pspsEvents={pspsEvents}
          />
        </div>
    </PageLayout>
  );
};

export default MapPage;

MapPage.propTypes = {
  electricVehicles: PropTypes.array,
  tabId: PropTypes.string,
  history: PropTypes.object,
  pspsEvents: PropTypes.array
};
