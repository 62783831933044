import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./ZipcodeInputWithButton.css";

const ZipcodeInputWithButton = ({
  buttonText,
  error,
  isLoading,
  onSubmitZipcode,
  zipcode,
  zipcodeLabel,
  zipcodePlaceholder,
  btnStyles,
  allowAddress,
  customId,
  onClick,
}) => {
  const [workingZipcode, setWorkingZipcode] = useState(zipcode);

  // Sync the internal working zipcode to an external value,
  // most probably one from context
  useEffect(
    () => {
      if (zipcode) {
        setWorkingZipcode(zipcode);
      }
    },
    [zipcode, setWorkingZipcode]
  );

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && isSubmittingAllowed()) {
      onSubmitZipcode(workingZipcode);
    }
  };

  const isSubmittingAllowed = () => {
    if (allowAddress) return true;

    return workingZipcode && workingZipcode.length >= 5 && !isLoading;
  };

  const uniqueString = Math.random()
    .toString()
    .slice(2);
  const uniqueId = customId || `zipcode-input-${uniqueString}`;

  return (
    <div className="ZipcodeInputWithButton">
      <div className="form-group">
        <label className="label-subTitle" htmlFor={uniqueId}>
          {zipcodeLabel || "Zip Code or Address"}
        </label>
        <input
          id={uniqueId}
          type="text"
          placeholder={zipcodePlaceholder || "Enter zipcode"}
          className="form-control"
          value={workingZipcode}
          onChange={(e) => setWorkingZipcode(e.target.value)}
          onKeyPress={handleKeyPress}
          onClick={onClick}
        />
      </div>
      <div className="form-group">
        <button
          style={btnStyles}
          className="btn-kia btn-block"
          type="button"
          onClick={() => onSubmitZipcode(workingZipcode)}
          disabled={!isSubmittingAllowed() || isLoading}
        >
          {buttonText}
        </button>
      </div>

      {error && (
        <div className="form-group">
          <p className="text-danger">{error}</p>
        </div>
      )}
    </div>
  );
};

ZipcodeInputWithButton.propTypes = {
  buttonText: PropTypes.string,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  onSubmitZipcode: PropTypes.func,
  zipcode: PropTypes.string,
  zipcodeLabel: PropTypes.string,
  btnStyles: PropTypes.object,
};

ZipcodeInputWithButton.defaultProps = {
  buttonText: "Update zipcode",
  isLoading: false,
  showStationsByPowerLevel: false,
  showStationsList: true,
  customizeMarkOnHover: () => {},
};

export default ZipcodeInputWithButton;
