const capitalizedInLease = incentives => {
  let incentivesArray = incentives || [];

  return incentivesArray.reduce((acc, incentive) => {
    let incentiveAmount =
      incentive &&
      incentive.evaluation &&
      incentive.evaluation.applicable_to_lease &&
      incentive.evaluation.capitalized_in_lease &&
      !isNaN(parseInt(incentive.evaluation.amount_in_lease, 10))
        ? parseInt(incentive.evaluation.amount_in_lease, 10)
        : 0;

    return acc + incentiveAmount;
  }, 0);
};

export default capitalizedInLease;
