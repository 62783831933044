import React from "react";
import GeneralEmergency from "../../assets/images/general_emergency";

const PspsEventsAlert = () => {
  return <>
    <GeneralEmergency className="mr-2" width="1.5rem" height="1.5rem" fill="#ffffff" />
    <div className="font-weight-bold d-inline-block">
      Public Safety Power Shutoff in effect. Please see the official PSPS page
      {" "}
      <a
        href="https://kiaalerts.alerts.kia.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        here
      </a>
      .
    </div>
  </>;
};

export default PspsEventsAlert;
