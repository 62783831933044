import "react-app-polyfill/ie11";
import React from "react";
import { render } from "react-dom";
import "./index.css";
import "./custom_boostrap.scss";
import PgeFrontend from "./containers/PgeFrontend/PgeFrontend";
import { unregister } from "./utils/registerServiceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import "./utils/polyfills";

const rootElement = document.getElementById("root");

render(
  <Router>
    <PgeFrontend />
  </Router>,
  rootElement
);

unregister();
