import React from "react";
import PropTypes from "prop-types";

import ChargingStationCard from "../ChargingStationCard/ChargingStationCard";
import ShowHideButton from "../shared/ShowHideButton/ShowHideButton";
import SelectedChargingStationCard from "../SelectedChargingStationCard/SelectedChargingStationCard";

import ChargingPinHighPower from "./../../assets/images/icons/charger-pin-high-power.svg";
import ChargingPinDefault from "./../../assets/images/icons/charger-pin-default.svg";

import "./ChargingStationsList.css";

const ChargingStationsList = ({ chargingStations, showLegendCard, publicIcon, highPowerIcon, defaultIcon, chargingListStyle, chargingListCardStyle, btnStyle, footerText, showStationsList }) => {
  return (
    <div className="ChargingStationsList">
      <ShowHideButton showBtn={showStationsList} footerText={footerText} style={btnStyle} buttonText="Full Station List">
        <div className="charging-station-cards-container">
          <div className="row">
            <div style={chargingListStyle} className="col-sm-6 offset-sm-3">
              {chargingStations.map((station, i) => {
                if(showLegendCard) {
                  return (
                    <SelectedChargingStationCard 
                      style={chargingListCardStyle}
                      station={station} 
                      publicIcon={publicIcon || ChargingPinDefault}
                      highPowerIcon={highPowerIcon || ChargingPinHighPower}
                      defaultIcon={defaultIcon || ChargingPinDefault}
                    />
                  )
                }
                return (
                  <ChargingStationCard
                    key={station.id}
                    style={chargingListCardStyle}
                    chargingStation={station}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </ShowHideButton>
    </div>
  );
};

ChargingStationsList.propTypes = {
  chargingStations: PropTypes.array
};

ChargingStationsList.defaultProps = {
  chargingStations: [],
  showLegendCard: false
};

export default ChargingStationsList;
