const fetchElectricVehicles = async params => {
  // Remove null params
  Object.keys(params).forEach(key => params[key] == null && delete params[key]);

  let url = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/vehicles`);

  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  const response = await window.fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`
    }
  });

  const json = await response.json();
  const electricVehicles = json.vehicles;

  let videoReviewsConverter = videoReviews => {
    return videoReviews.map(videoReview => {
      return {
        // Not used in FE and not provided in UAPI
        position: null,

        title: videoReview.reviewer,
        youtube_video_id: videoReview.video_url
          .split("https://www.youtube.com/embed/")
          .pop()
      };
    });
  };

  let equivalentGasVehicleConverter = egc => {
    return {
      // Not used in FE and not provided in UAPI
      form_factor: "",
      fuel_type: "Gas",
      seats: 0,
      total_range_in_mi: 1,
      model_variant: "",

      vehicle_id: egc.epa_id.toString(),
      gasoline_mpg: egc.fossil_fuel_efficiency,
      make: egc.make,
      model: egc.model,
      model_year: egc.model_year,
      msrp: egc.msrp
    };
  };

  let converter = vehiclesArray => {
    return vehiclesArray.map(vehicle => {
      return {
        // action_images: not used in the front end
        // available_locally: not used in the front end
        // cost_of_ownership: not used in the front end

        // Unsure if Used in front end and not provided by unified api
        // image:
        // id:

        // Exact Field Names
        msrp: vehicle.msrp,
        form_factor: vehicle.form_factor,
        make: vehicle.make,
        model_year: vehicle.model_year,
        model: vehicle.model,
        dc_charging_power: vehicle.dc_charging_power,
        acceleration: vehicle.acceleration,
        official_website_url: vehicle.official_website_url,
        drivetrain: vehicle.drivetrain || vehicle.drive_train,

        // Similar Field Names
        electric_range_in_mi: vehicle.electric_range,
        fuel_type: vehicle.fuel,
        gasoline_mpg: vehicle.fossil_fuel_efficiency,
        kwh_per_100_mi: vehicle.electric_efficiency,
        max_ac_intake_in_kw: vehicle.ac_charging_power,
        model_variant: vehicle.trim,
        max_seats: vehicle.seats_max,
        min_seats: vehicle.seats_min,
        total_range_in_mi: vehicle.total_range,
        battery_capacity_in_kwh: vehicle.battery_capacity,
        vehicle_id: vehicle.handle,

        // Nested Fields
        incentives: vehicle.incentives,
        images: vehicle.images,
        video_reviews: videoReviewsConverter(vehicle.video_reviews || []),
        equivalent_gas_vehicle: vehicle.equivalent_gas_vehicle
          ? equivalentGasVehicleConverter(vehicle.equivalent_gas_vehicle)
          : {}
      };
    });
  };
  return converter(electricVehicles);
};

export default fetchElectricVehicles;
