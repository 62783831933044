import React from "react";
import PropTypes from "prop-types";

import SelectedDealerCard from "../SelectedDealerCard/SelectedDealerCard";
import ShowHideButton from "../shared/ShowHideButton/ShowHideButton";

import "./DealersList.css";

const findOemImage = (images, dealer) => {
  return images.find(
    image => image.name.toUpperCase() === dealer.oem.toUpperCase()
  );
};

const DealersList = ({ dealers, oemImages }) => {
  return (
    <div className="DealersList">
      <ShowHideButton buttonText="full list of dealers">
        <div className="row">
          {dealers.map((dealer, i) => {
            return (
              <div className="col-sm-4" key={dealer.dealership_handle}>
                <SelectedDealerCard
                  {...dealer}
                  oemImage={findOemImage(oemImages, dealer)}
                />
              </div>
            );
          })}
        </div>
      </ShowHideButton>
    </div>
  );
};

DealersList.propTypes = {
  dealers: PropTypes.array,
  oemImages: PropTypes.array
};

DealersList.defaultProps = {
  dealers: [],
  oemImages: []
};

export default DealersList;
