import React from "react";
import PropTypes from "prop-types";

import "./SelectedChargingStationCard.css";

const SelectedChargingStationCard = ({ station, publicIcon, highPowerIcon, defaultIcon, style }) => {
  if (!station) return null;

  const {
    station_name: stationName,
    street_address: streetAddress,
    city,
    state,
    zip,
    station_phone: stationPhone,
    ev_network: evNetwork,
    ev_network_web: evNetworkWeb
  } = station;

  let iconType =
    Number(station.ev_dc_fast_num)
      ? highPowerIcon
      : station.access_code === "public"
        ? publicIcon
        : defaultIcon;

        
  return (
    <div className="SelectedChargingStationCard" style={style}>
      <div className="card-left">
        <img className="card-icon" src={iconType} alt="Kia Motors Default Pin" />
      </div>
      <div className="card-right">
        <p>{stationName}</p>
        <p>{streetAddress}</p>
        <p> {`${city}, ${state} ${zip}`} </p>
        <p>{stationPhone} </p>
        <div className="card-hyperlink">
          {evNetwork &&
            evNetworkWeb && (
              <a href={evNetworkWeb} target="_blank" rel="noopener noreferrer">
                {evNetwork}
              </a>
            )}
        </div>
      </div>
    </div>
  );
};

export default SelectedChargingStationCard;

SelectedChargingStationCard.propTypes = {
  station: PropTypes.object
};
