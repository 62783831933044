import React, { useState } from "react";
import PropTypes from "prop-types";

import "./ShowHideButton.css";

const ShowHideButton = ({
  children,
  buttonText,
  displayChildrenOnlyOnTablet,
  displayChildrenOnlyOnMobile,
  displayAlertOnlyOnMobile,
  style,
  footerText,
  showBtn
}) => {
  const [showChildren, setShowChildren] = useState(false);

  const toggleButton = () => {
    setShowChildren(!showChildren);
  };

  let renderChildren = showChildren ? children : null;
  let renderButtonText = showChildren
    ? "Hide " + buttonText
    : "Show " + buttonText;

  let button = (
    <div style={{width: '100%', display: 'flex'}}>

      {footerText && 
        <p className="listFooterText d-none d-lg-flex">
          {footerText}
        </p>
      }
      
      {showBtn && <button
        type="button"
        className="btn"
        onClick={toggleButton}
        style={style || {
          border: "1px solid #DBDBDB",
          margin: "0 5px 10px 0",
          fontWeight: "400",
          fontSize: "0.85rem",
          minWidth: "170px"
        }}
      >
        {renderButtonText}
      </button>}

    </div>
  );

  let desktopClasses = "show-full-list-container d-none d-md-block";
  let mobileClasses = "d-block d-md-none";
  if (displayChildrenOnlyOnTablet) {
    desktopClasses = "show-full-list-container d-none d-lg-block";
    mobileClasses = "d-block d-lg-none";
  }

  return displayChildrenOnlyOnTablet ||
    displayChildrenOnlyOnMobile ||
    displayAlertOnlyOnMobile ? (
    <>
      <div className={desktopClasses}>
        {button}
        {renderChildren}
      </div>
      <div className={mobileClasses}>
        {displayChildrenOnlyOnMobile && children}
        {displayChildrenOnlyOnTablet && children}
        {displayAlertOnlyOnMobile && (
          <div className="input-well">
            <p>
              For more details including charts &amp; calculations, please view
              this page on a desktop.
            </p>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="show-full-list-container">
      {button}
      {renderChildren}
    </div>
  );
};

export default ShowHideButton;

ShowHideButton.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  displayChildrenOnlyOnTablet: PropTypes.bool,
  displayChildrenOnlyOnMobile: PropTypes.bool,
  displayAlertOnlyOnMobile: PropTypes.bool
};
