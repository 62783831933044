import React from "react";
import PropTypes from "prop-types";

import "./SelectedDealerCard.css";
import PhoneIcon from "../../assets/images/icons/icn_phone.svg";
import LinkIcon from "../../assets/images/icons/icn_link.svg";

const SelectedDealerCard = ({
  name,
  street,
  city,
  region,
  postcode,
  phone,
  website,
  oemImage
}) => {
  const secondLineAddress = [city, region, postcode]
    .filter(el => el)
    .join(", ");

  const handleClickWebsite = () => {
  };

  const handleClickPhone = () => {
  };

  return (
    <div className="SelectedDealerCard">
      {oemImage && (
        <img
          src={oemImage.image_url}
          alt={oemImage.name}
          className="oem-image"
        />
      )}
      <h5>{name || ""}</h5>
      <p className="address-container">
        {street || ""}
        <br />
        {secondLineAddress || ""}
      </p>
      <div className="links-container">
        <div className="row">
          {phone && (
            <div className="col-6">
              <img src={PhoneIcon} alt="" />
              &nbsp;
              <a href={`tel: ${phone}`} onClick={handleClickPhone}>
                Contact
              </a>
            </div>
          )}
          {website && (
            <div className="col-6">
              <img src={LinkIcon} alt="" />
              &nbsp;
              <a
                href={website}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleClickWebsite}
              >
                Website
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SelectedDealerCard.propTypes = {
  name: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  postcode: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  oemImage: PropTypes.shape({
    name: PropTypes.string,
    image_url: PropTypes.string
  })
};

export default SelectedDealerCard;
