import React from "react";
import PropTypes from "prop-types";
import SelectedChargingStationCard from "../SelectedChargingStationCard/SelectedChargingStationCard";
import ChargingStationsMapLegend from "../ChargingStationsMapLegend/ChargingStationsMapLegend";

import ChargingPinHighPower from "./../../assets/images/icons/charger-pin-high-power.svg";
import ChargingPinDefault from "./../../assets/images/icons/charger-pin-default.svg";
import ChargingPinPublic from "./../../assets/images/icons/charger-pin-public.png";

import "./MapControlPanel.css";

const MapControlPanel = ({ 
  chargingStations, 
  children, 
  selectedStation, 
  showStationsByPowerLevel,
  highPowerLegendTitle,
  highPowerLegendLabel,
  otherTypesLegendTitle,
  otherTypesLegendLabel,
  publicLegendTitle,
  publicLegendLabel,
  publicIcon,
  highPowerIcon,
  defaultIcon,
  alwaysShowLegend,
}) => {
  return (
    <div className="MapControlPanel">
      <div className="map-controls">{children}</div>
      {((chargingStations || []).length > 0 || alwaysShowLegend) && (
        <>
          <SelectedChargingStationCard 
            station={selectedStation} 
            publicIcon={publicIcon || showStationsByPowerLevel ? ChargingPinDefault : ChargingPinPublic}
            highPowerIcon={highPowerIcon || ChargingPinHighPower}
            defaultIcon={defaultIcon || ChargingPinDefault}
          />
          <ChargingStationsMapLegend 
            chargingStations={chargingStations} 
            showStationsByPowerLevel={showStationsByPowerLevel} 
            highPowerLegendTitle={highPowerLegendTitle}
            highPowerLegendLabel={highPowerLegendLabel}
            otherTypesLegendTitle={otherTypesLegendTitle}
            otherTypesLegendLabel={otherTypesLegendLabel}
            publicLegendTitle={publicLegendTitle}
            publicLegendLabel={publicLegendLabel}
            publicIcon={publicIcon}
            highPowerIcon={highPowerIcon}
            defaultIcon={defaultIcon}
            alwaysShowLegend
          />
        </>
      )}
    </div>
  );
};

MapControlPanel.propTypes = {
  chargingStations: PropTypes.array,
  children: PropTypes.node,
  selectedStation: PropTypes.object,
  showStationsByPowerLevel: PropTypes.bool,
  highPowerLegendTitle: PropTypes.string,
  highPowerLegendLabel: PropTypes.string,
  otherTypesLegendTitle: PropTypes.string,
  otherTypesLegendLabel: PropTypes.string,
  publicLegendTitle: PropTypes.string,
  publicLegendLabel: PropTypes.string
};

MapControlPanel.defaultProps = {
  chargingStations: [],
  showStationsByPowerLevel: false
};

export default MapControlPanel;
