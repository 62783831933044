import React from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

import ChargingStationsMap from "../ChargingStationsMap/ChargingStationsMap";
import TravelRouteMap from "../TravelRouteMap/TravelRouteMap";
import TravelRadiusMap from "../TravelRadiusMap/TravelRadiusMap";
import DealersMap from "../DealersMap/DealersMap";

import ChargingPinPublic from "../../assets/images/icons/charger-pin-default.svg";
import ChargingPinHighPower from "../../assets/images/icons/charger-pin-high-power.svg";

import ChargingPinPublicHover from "../../assets/images/icons/charger-pin-default-hover.svg";
import ChargingPinHighPowerHover from "../../assets/images/icons/charger-pin-high-power-hover.svg";

import stationFilter from "../../constants/stationsFilter";

import {
  ALL_STATIONS_TAB_ID,
  ROUTE_MAP_TAB_ID,
  RANGE_FROM_FULL_CHARGE_TAB_ID,
  DEALER_CATALOG_TAB_ID,
  MAP_TABS
} from "../../constants/mapTabs";

import "../../assets/css/tabs.css";
import "./TabbedMaps.css";

const activeClass = (tabNumber, activeTab) =>
  activeTab === tabNumber ? "active" : "";

const TabbedMaps = ({  
  electricVehicles,
  oems,
  activeTabId,
  toggle,
  canIgnoreLowPowerStations = true,
  pspsEvents = [],
  mobileTitle
}) => {
  const chargingStationsFilterFn = (station) => {    
    if(station.ev_connector_types && (station.ev_connector_types.includes("J1772") || station.ev_connector_types.includes("CCS1"))) {
      let str = station.station_name.toUpperCase()
      if(stationFilter.some(v => str.includes(v.toUpperCase()))) {
        return
      }

      return station
    }

    return
  }

  return (
    <div className="TabbedMaps">
      <div className="form-group d-lg-none">
        <select
          value={activeTabId}
          onChange={e => toggle(e.target.value)}
          className="form-control"
        >
          {MAP_TABS.map(tab => (
            <option key={tab.name} value={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="tabbed-maps-panel">
        <Nav tabs className="d-none d-lg-flex">
          {MAP_TABS.map(tab => (
            <NavItem key={tab.name}>
              <NavLink
                className={"navlink " + activeClass(tab.id, activeTabId)}
                onClick={() => {
                  toggle(tab.id);
                }}
              >
                {tab.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTabId}>
          <TabPane tabId={ALL_STATIONS_TAB_ID}>
            <ChargingStationsMap
              chargingStationsFilterFn={chargingStationsFilterFn}
              isVisible={activeTabId === ALL_STATIONS_TAB_ID}
              canIgnoreLowPowerStations={true}
              pspsEvents={pspsEvents}      
              defaultIcon={ChargingPinPublic}
              highPowerIcon={ChargingPinHighPower}
              defaultIconSelected={ChargingPinPublicHover}
              highPowerIconSelected={ChargingPinHighPowerHover}
              otherTypesLegendTitle={"Power Stations"} 
              otherTypesLegendLabel={"Level 2 chargers"}
              highPowerLegendTitle={"High Power Stations"}
              highPowerLegendLabel={"DC fast chargers"}
              controlPanelTitle={"Enter your Postal Code or address to find EV charging stations near you."}
              zipcodePlaceholder="Choose a location"
              zipcodeLabel={"Zip Code or Address"}
              showStationsByPowerLevel
              showStationsList={true}
              customizeMarkOnHover={styles => {
                styles.transform = "scale(1.2)"              
              }}
              showLegendCard={true}
              chargingListStyle={{margin: '0', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '100%', flex: '0 0 100%'}}
              chargingListCardStyle={{margin: '0 12px 20px 12px', flexDirection: 'row', justifyContent: 'flex-start', width: '285px', height: '137px', alignItems: 'center', padding: '24px 16px', borderRadius: '0px'}}
              searchBtnlabel="Search charging stations"
              btnStyles={{width: '100%'}}
              showListBtnStyle={{border: 'none', color: '#005996', textDecoration: 'underline', margin: '0 0 0 auto', fontStyle: 'normal', fontWeight: 'normal', fontSize: '14px', lineHeight: '17px', minWidth: '170px'}}
              footerText=''
              allowAddress
            />
          </TabPane>
          <TabPane tabId={ROUTE_MAP_TAB_ID}>
            <TravelRouteMap
              showDistanceSelector={true}
              chargingStationsFilterFn={chargingStationsFilterFn}
              isVisible={activeTabId === ROUTE_MAP_TAB_ID}
              canIgnoreLowPowerStations={true}
              showStationsList={true}
              pspsEvents={pspsEvents}      
              defaultIcon={ChargingPinPublic}
              highPowerIcon={ChargingPinHighPower}
              defaultIconSelected={ChargingPinPublicHover}
              highPowerIconSelected={ChargingPinHighPowerHover}
              startInputPlaceholder="Choose a starting location"
              endInputPlaceholder="Choose a final destination"
              otherTypesLegendTitle={"Power Stations"} 
              otherTypesLegendLabel={"Level 2 chargers"}
              highPowerLegendTitle={"High Power Stations"}
              highPowerLegendLabel={"DC fast chargers"}
              controlPanelTitle={"Find EV charging stations along your route and plan your trip with ease."}
              showStationsByPowerLevel
              customizeMarkOnHover={styles => {
                styles.transform = "scale(1.2)"              
              }}
              showLegendCard={true}
              chargingListStyle={{margin: '0', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', maxWidth: '100%', flex: '0 0 100%'}}
              chargingListCardStyle={{margin: '0 12px 20px 12px', flexDirection: 'row', justifyContent: 'flex-start', width: '345px', height: '137px', alignItems: 'center', padding: '24px 16px', borderRadius: '0px'}}
              searchBtnlabel="Search charging stations"
              btnStyles={{width: '100%'}}
              showListBtnStyle={{border: 'none', color: '#005996', textDecoration: 'underline', margin: '0 0 0 auto', fontStyle: 'normal', fontWeight: 'normal', fontSize: '14px', lineHeight: '17px', minWidth: '170px'}}
              footerText=''
            />
          </TabPane>
          <TabPane tabId={RANGE_FROM_FULL_CHARGE_TAB_ID}>
            <TravelRadiusMap
              onlyTotalRange              
              totalRangeLegend=" miles total range*"
              chargingStationsFilterFn={chargingStationsFilterFn}
              electricVehicles={electricVehicles}
              isVisible={activeTabId === RANGE_FROM_FULL_CHARGE_TAB_ID}
              canIgnoreLowPowerStations={false}
              zipcodePlaceholder="Choose a location"
              pspsEvents={pspsEvents}    
              controlPanelTitle={"Discover your travel range and ride with confidence."}
              showStationsByPowerLevel
              showLegendCard={false}
              hideStations
              btnStyles={{width: '100%'}}
              showListBtnStyle={{border: 'none', color: '#005996', textDecoration: 'underline', margin: '0 0 0 auto', fontStyle: 'normal', fontWeight: 'normal', fontSize: '14px', lineHeight: '17px', minWidth: '170px'}}
              footerText=''
            />
          </TabPane>

          <TabPane tabId={DEALER_CATALOG_TAB_ID}>
            <DealersMap
              isVisible={activeTabId === DEALER_CATALOG_TAB_ID}
              oems={oems}
              pspsEvents={pspsEvents}
            />
          </TabPane>
        </TabContent>
      </div>
    </div>
  )
};

TabbedMaps.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  electricVehicles: PropTypes.array,
  oems: PropTypes.array,
  activeTabId: PropTypes.string,
  toggle: PropTypes.func,
  canIgnoreLowPowerStations: PropTypes.bool,
  pspsEvents: PropTypes.array,
  geojsonUrls: PropTypes.array
};

export default TabbedMaps;
