const boolDefault = val => (typeof val === "boolean" ? val : null);

const loadUserPrefs = savedPrefs => {
  return {
    canTurnInClunker: boolDefault(savedPrefs.canTurnInClunker),
    chargerInstallAllowed: boolDefault(savedPrefs.chargerInstallAllowed),
    chargerLevel: savedPrefs.chargerLevel || null,
    closedExperienceSurveyAt: savedPrefs.closedExperienceSurveyAt || null,
    comparedVehicleId1: savedPrefs.comparedVehicleId1 || null,
    comparedVehicleId2: savedPrefs.comparedVehicleId2 || null,
    currentElectricRate: savedPrefs.currentElectricRate || null,
    electricMilesPortionForPhev: savedPrefs.electricMilesPortionForPhev || null,
    equivalentMilesPerGallon: savedPrefs.equivalentMilesPerGallon || null,
    gasolinePriceInCentsPerGal: savedPrefs.gasolinePriceInCentsPerGal || null,
    hasOvernightCharger: boolDefault(savedPrefs.hasOvernightCharger),
    hasRegularPowerOutlet: boolDefault(savedPrefs.hasRegularPowerOutlet),
    hasSolarAtHome: boolDefault(savedPrefs.hasSolarAtHome),
    homeElectricBillMonthlyAvg: savedPrefs.homeElectricBillMonthlyAvg || null,
    householdIncome: savedPrefs.householdIncome || null,
    householdSize: savedPrefs.householdSize || null,
    includeResaleValue: boolDefault(savedPrefs.includeResaleValue),
    interestRateAsBasisPoints: savedPrefs.interestRateAsBasisPoints || null,
    maxBudget: savedPrefs.maxBudget || null,
    milesDrivenAnnually: savedPrefs.milesDrivenAnnually || null,
    milesDrivenDaily: savedPrefs.milesDrivenDaily || null,
    minSeats: savedPrefs.minSeats || null,
    monthsOfOwnership: savedPrefs.monthsOfOwnership || null,
    parkingLocation: savedPrefs.parkingLocation || null,
    publicChargingCostInCentsPerKwh:
      savedPrefs.publicChargingCostInCentsPerKwh || null,
    publicChargingPercentage: savedPrefs.publicChargingPercentage || null,
    purchaseMethod: savedPrefs.purchaseMethod || null,
    showAllRates: boolDefault(savedPrefs.showAllRates),
    showGasolineCost: boolDefault(savedPrefs.showGasolineCost),
    showHomeElectricityCost: boolDefault(savedPrefs.showHomeElectricityCost),
    showRateDetails: boolDefault(savedPrefs.showRateDetails),
    showDetailsForRate: savedPrefs.showDetailsForRate || null,
    taxFilingStatus: savedPrefs.taxFilingStatus || null,
    vehicleChargingPattern: savedPrefs.vehicleChargingPattern || null,
    vehicleIdForIncentives: savedPrefs.vehicleIdForIncentives || null,
    vehicleIdForRateComp: savedPrefs.vehicleIdForRateComp || null,
    vehicleMakeFilter: savedPrefs.vehicleMakeFilter || null,
    vehicleSortDirection: savedPrefs.vehicleSortDirection || null,
    vehicleSortType: savedPrefs.vehicleSortType || null,
    zipcode: savedPrefs.zipcode || null,

    vehicleFuelTypeFilters: {
      bev: boolDefault(savedPrefs.vehicleFuelTypeFilters.bev),
      phev: boolDefault(savedPrefs.vehicleFuelTypeFilters.phev)
    },

    vehicleFormFactorFilters: {
      sedan: boolDefault(savedPrefs.vehicleFormFactorFilters.sedan),
      hatchback: boolDefault(savedPrefs.vehicleFormFactorFilters.hatchback),
      coupe: boolDefault(savedPrefs.vehicleFormFactorFilters.coupe),
      crossover: boolDefault(savedPrefs.vehicleFormFactorFilters.crossover),
      minivan: boolDefault(savedPrefs.vehicleFormFactorFilters.minivan),
      suv: boolDefault(savedPrefs.vehicleFormFactorFilters.suv),
      "station wagon": boolDefault(savedPrefs.vehicleFormFactorFilters["station wagon"]),
      truck: boolDefault(savedPrefs.vehicleFormFactorFilters.truck)
    }
  };
};

export default loadUserPrefs;
