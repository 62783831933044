import { E_1, EV2_A } from "../../constants/rates";

const USER_PREF_PRESETS = {
  canTurnInClunker: true,
  chargerInstallAllowed: null,
  chargerLevel: "level_2",
  closedExperienceSurveyAt: null,
  comparedVehicleId1: null,
  comparedVehicleId2: null,
  currentElectricRate: E_1,
  electricMilesPortionForPhev: 60,
  equivalentMilesPerGallon: 25,
  gasolinePriceInCentsPerGal: 295,
  hasOvernightCharger: null,
  hasRegularPowerOutlet: null,
  hasSolarAtHome: false,
  homeElectricBillMonthlyAvg: 125,
  householdIncome: 75000,
  householdSize: 1,
  includeResaleValue: true,
  interestRateAsBasisPoints: 350,
  maxBudget: 30000,
  milesDrivenAnnually: 20000,
  milesDrivenDaily: 30,
  minSeats: 2,
  monthsOfOwnership: 60,
  parkingLocation: "",
  publicChargingCostInCentsPerKwh: 20,
  publicChargingPercentage: 0,
  purchaseMethod: "cash",
  showAllRates: false,
  showGasolineCost: true,
  showHomeElectricityCost: true,
  showRateDetails: true,
  showDetailsForRate: EV2_A,
  taxFilingStatus: "single",
  vehicleChargingPattern: "After midnight; Before 3pm",
  vehicleIdForIncentives: "",
  vehicleIdForRateComp: "",
  vehicleMakeFilter: "All",
  vehicleSortDirection: "desc",
  vehicleSortType: "match_score",
  zipcode: "94018",

  vehicleFuelTypeFilters: {
    bev: false,
    phev: false
  },

  vehicleFormFactorFilters: {
    sedan: false,
    hatchback: false,
    coupe: false,
    crossover: false,
    minivan: false,
    suv: false,
    "station wagon": false,
    truck: false
  }
};

export default USER_PREF_PRESETS;
