import React from "react";
import PropTypes from "prop-types";

import ChargingPinHighPower from "./../../assets/images/icons/charger-pin-high-power.svg";
import ChargingPinDefault from "./../../assets/images/icons/charger-pin-default.svg";

import isSuperChargingStation from "../../utils/predicates/isSuperChargingStation";

import "./ChargingStationsMapLegend.css";

const ChargingStationsMapLegend = ({ 
  chargingStations, 
  showStationsByPowerLevel,
  highPowerLegendTitle,
  highPowerLegendLabel,
  otherTypesLegendTitle,
  otherTypesLegendLabel,
  publicLegendTitle,
  publicLegendLabel,
  publicIcon,
  highPowerIcon,
  defaultIcon,
  alwaysShowLegend
}) => {
  const hasSuperChargerStations =
    (chargingStations || []).filter(station => isSuperChargingStation(station))
      .length > 0;

  return (
    <div className="legend ChargingStationsMapLegend">
      {(hasSuperChargerStations || alwaysShowLegend) && (
        <div className="charger-type">
          <b>{highPowerLegendTitle || 'High Power Stations'}</b>
          <br />
          <span>{highPowerLegendLabel || 'DC fast charge or superchargers'}</span>
          <img src={highPowerIcon || ChargingPinHighPower} alt="Kia Motors High Power Pin" />
        </div>
      )}
      {!showStationsByPowerLevel && (
        <div className="charger-type">
          <b>{publicLegendTitle || 'Power stations'}</b>
          <br />
          <span>{publicLegendLabel || 'Installed by business or government'}</span>
          <img src={publicIcon || ChargingPinDefault} alt="Kia Motors Public Pin" />
        </div>
      )}
      <div className="charger-type">
        <b>{otherTypesLegendTitle || 'Other Types of Stations'}</b>
        <br />
        <span>{otherTypesLegendLabel || 'Private stations'}</span>
        <img src={defaultIcon || ChargingPinDefault} alt="Kia Motors Default Pin" />
      </div>
    </div>
  );
};

ChargingStationsMapLegend.propTypes = {
  chargingStations: PropTypes.array,
  showStationsByPowerLevel: PropTypes.bool,
  highPowerLegendTitle: PropTypes.string,
  highPowerLegendLabel: PropTypes.string,
  otherTypesLegendTitle: PropTypes.string,
  otherTypesLegendLabel: PropTypes.string,
  publicLegendTitle: PropTypes.string,
  publicLegendLabel: PropTypes.string
};

ChargingStationsMapLegend.defaultProps = {
  chargingStations: [],
  showStationsByPowerLevel: false
};

export default ChargingStationsMapLegend;
