import React from "react";
import PropTypes from "prop-types";
import { UncontrolledTooltip } from "reactstrap";
import q from "../../../assets/images/icons/icon-q.png";

const ToolTip = ({ id, message }) => {
  const randomishNumber = Math.random()
    .toString(36)
    .substr(2, 9);
  const randomId = `tooltip-${randomishNumber}`;
  return (
    <>
      <span id={id || randomId} style={{ display: "inline-block" }}>
        <img
          src={q}
          alt=""
          style={{ width: "16px", marginLeft: "5px", marginBottom: "3px" }}
        />
      </span>
      <UncontrolledTooltip placement="right" target={id || randomId}>
        <span>{message}</span>
      </UncontrolledTooltip>
    </>
  );
};

export default ToolTip;

ToolTip.propTypes = {
  id: PropTypes.string,
  message: PropTypes.string
};
