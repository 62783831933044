import { useState } from "react";
import GaTracker from "../utils/GaTracker/GaTracker";
import { MAP_TABS, ALL_STATIONS_TAB_ID } from "../constants/mapTabs";

const useMapTabs = initialTabId => {
  const [activeTabId, setActiveTabId] = useState(
    initialTabId || ALL_STATIONS_TAB_ID
  );

  const toggle = (tabId, { isTrackingEvent } = { isTrackingEvent: true }) => {
    if (activeTabId !== tabId) {      
      const newTab = MAP_TABS.find(tab => tab.id === tabId);


      isTrackingEvent &&
        GaTracker.trackEvent({
          category: "Maps",
          action: `Clicked ${newTab.name} tab`
        });
        
      setActiveTabId(tabId);
    }
  };

  const findTab = tabId =>
    MAP_TABS.find(tab => tab.id === tabId) || MAP_TABS[0];

  return {
    activeTabId,
    findTab,
    toggle
  };
};

export default useMapTabs;
