import React from "react";
import PropTypes from "prop-types";

import "./ChargingStationCard.css";

const ChargingStationCard = ({
  chargingStation: {
    street_address: streetAddress,
    city,
    state,
    zip,
    station_name: stationName,
    access_code: accessCode,
    ev_network: evNetwork,
    ev_network_web: evNetworkWeb
  } = {}
}) => {
  let address = `${streetAddress}, ${city}, ${state} ${zip}`;
  return (
    <div className="ChargingStationCard">
      <div>
        <b>{stationName}</b> {address}
        &nbsp;
        <span className="badge badge-secondary">{accessCode}</span>
      </div>
      <div>
        {evNetwork &&
          evNetworkWeb && (
            <a href={evNetworkWeb} target="_blank" rel="noopener noreferrer">
              {evNetwork}
            </a>
          )}
      </div>
    </div>
  );
};

ChargingStationCard.propTypes = {
  chargingStation: PropTypes.object
};

export default ChargingStationCard;
