import { useState, useRef, useCallback } from "react";

const fetchDataFromApi = async (
  { lat, lng, url, radiusInMi, token },
  signal
) => {
  let options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    signal,
  };

  let params = {
    lat,
    long: lng,
    distance: radiusInMi,
  };

  let fullUrl = new URL(url);
  let searchParams = new URLSearchParams(params);

  fullUrl.search = searchParams;

  const response = await window.fetch(fullUrl, options);
  const data = await response.json();
  return data;
};

const useDealers = () => {
  const [loading, setLoading] = useState(false);
  const [dealers, setDealers] = useState([]);
  const [error, setError] = useState(undefined);

  const abortControllerRef = useRef(null);

  const fetchDealers = useCallback(async ({ lat, lng, radiusInMi }) => {
    if (!lat || !lng || !radiusInMi) return;

    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new window.AbortController();
      setLoading(true);

      let url = `${process.env.REACT_APP_EV_INFO_API_HOST}/dealers`;
      let token = process.env.REACT_APP_EV_INFO_TKN;

      const data = await fetchDataFromApi(
        {
          url,
          lat,
          lng,
          radiusInMi: Math.ceil(radiusInMi),
          token,
        },
        abortControllerRef.current.signal
      );

      abortControllerRef.current = null;
      setDealers(data.dealers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
      abortControllerRef.current = null;
    }
  }, []);

  return {
    dealers,
    fetchDealers,
    loading,
    error,
  };
};

export default useDealers;
