import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import useMapBounds from "../../hooks/useMapBounds";
import useSelectedStation from "../../hooks/useSelectedStation";
import useChargingStationsNearRoute from "../../hooks/useChargingStationsNearRoute";

import setupGoogleMapsAutocomplete from "../../utils/setupGoogleMapsAutocomplete";
import isSuperChargingStation from "../../utils/predicates/isSuperChargingStation";
import GaTracker from "../../utils/GaTracker/GaTracker";

import BaseGoogleMapReact from "../BaseGoogleMapReact/BaseGoogleMapReact";
import MapControlPanel from "../MapControlPanel/MapControlPanel";
import ChargingStationsList from "../ChargingStationsList/ChargingStationsList";
import Checkbox from "../shared/InputElements/Checkbox";
import useGeoJsonUrls from "../../hooks/useGeojsonUrls";
import MapLegend from "../MapLegend/MapLegend";
import PspsEventLegend from "../PspsEventLegend/PspsEventLegend";

import StartIcon from "./../../assets/images/icons/start-icon.png";
import EndIcon from "./../../assets/images/icons/end-icon.png";

import "./TravelRouteMap.css";

const overviewPathAsLinestring = (overviewPath) => {
  const coordsAsText = overviewPath
    .map((coords) => `${coords.lng()} ${coords.lat()}`)
    .join(", ");
  return `LINESTRING(${coordsAsText})`;
};

const TravelRouteMap = ({
  chargingStationsFilterFn,
  isVisible = true,
  canIgnoreLowPowerStations = true,
  pspsEvents = [],
  showStationsByPowerLevel,
  showStationsList,
  highPowerLegendTitle,
  highPowerLegendLabel,
  otherTypesLegendTitle,
  otherTypesLegendLabel,
  publicLegendTitle,
  publicLegendLabel,
  publicIcon,
  highPowerIcon,
  defaultIcon,
  publicIconSelected,
  highPowerIconSelected,
  defaultIconSelected,
  controlPanelTitle,
  customizeMarkOnHover,
  btnStyles,
  showLegendCard,
  chargingListStyle,
  chargingListCardStyle,
  searchBtnlabel,
  showListBtnStyle,
  footerText,
  showDistanceSelector,
  startInputPlaceholder,
  endInputPlaceholder,
}) => {
  const [distance, setDistance] = useState(5);
  const [startAddress, setStartAddress] = useState();
  const [showRealMap, setShowRealMap] = useState(false);
  const [destinationAddress, setDestinationAddress] = useState();
  const [isFetchingRoute, setIsFetchingRoute] = useState(false);
  const [routeError, setRouteError] = useState();
  const [isIgnoringLowPowerStations, setIsIgnoringLowPowerStations] = useState(
    false
  );
  const [routeDistanceInMeters, setRouteDistanceInMeters] = useState();
  const [hoveredStation, setHoveredStation] = useState(false);

  const directionsServiceRef = useRef(null);
  const directionsRendererRef = useRef(null);
  const autocompleteStartLocationRef = useRef(null);
  const autocompleteEndLocationRef = useRef(null);

  const { registerMapBoundsListeners, filterWithinBounds } = useMapBounds();

  const {
    chargingStations,
    fetchChargingStations,
    error: chargingStationsError,
  } = useChargingStationsNearRoute();

  const [selectedStation, selectStation] = useSelectedStation(chargingStations);

  const registerGeojsonUrlMap = useGeoJsonUrls(
    pspsEvents.flatMap((event) => event.file_urls)
  );

  const onCompleteStartLocation = () => {
    const address = autocompleteStartLocationRef.current.getPlace()
      .formatted_address;
    setStartAddress(address);
  };

  const onCompleteEndLocation = () => {
    const address = autocompleteEndLocationRef.current.getPlace()
      .formatted_address;
    setDestinationAddress(address);
  };

  const saveMapRefs = ({ map }) => {
    /* eslint-disable no-undef */
    directionsServiceRef.current = new google.maps.DirectionsService();
    directionsRendererRef.current = new google.maps.DirectionsRenderer();
    /* eslint-enable no-undef */

    setupGoogleMapsAutocomplete(
      autocompleteStartLocationRef,
      "travel-route-map-start-location",
      onCompleteStartLocation
    );
    setupGoogleMapsAutocomplete(
      autocompleteEndLocationRef,
      "travel-route-map-end-location",
      onCompleteEndLocation
    );

    directionsRendererRef.current.setMap(map);
    registerMapBoundsListeners(map);
    fetchRoute({ isTrackingEvent: false });

    registerGeojsonUrlMap(map);
  };

  const isSubmittingAllowed = () =>
    !isFetchingRoute && startAddress && destinationAddress;

  const fetchRoute = useCallback(
    ({ isTrackingEvent = true }) => {
      if (!startAddress || !destinationAddress) return;
      setIsFetchingRoute(true);
      setRouteError(undefined);
      setRouteDistanceInMeters(undefined);
      if (isTrackingEvent) {
        GaTracker.trackEvent({
          category: "Maps",
          action: "Searched Travel Route",
        });
      }

      directionsServiceRef.current.route(
        {
          origin: startAddress,
          destination: destinationAddress,
          travelMode: "DRIVING",
        },
        (response, status) => {
          if (status === "OK") {
            directionsRendererRef.current.setDirections(response);

            if (response.routes && response.routes.length > 0) {
              // See https://developer.nrel.gov/docs/transportation/alt-fuel-stations-v1/nearby-route/
              const linestring = overviewPathAsLinestring(
                response.routes[0].overview_path
              );

              fetchChargingStations({ route: linestring, distance });
              const distanceInMeters = response.routes[0].legs.reduce(
                (totalDistance, leg) => totalDistance + leg.distance.value,
                0
              );
              setRouteDistanceInMeters(distanceInMeters);
            }
          } else {
            setRouteError("Error fetching route");
          }
          setIsFetchingRoute(false);
        }
      );
    },
    [
      startAddress,
      destinationAddress,
      fetchChargingStations,
      directionsRendererRef,
      distance,
    ]
  );

  useEffect(
    () => {
      if (!directionsServiceRef.current) return;

      // fetchRoute({ isTrackingEvent: false });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [distance, fetchRoute]
  );

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && isSubmittingAllowed()) {
      fetchRoute({ isTrackingEvent: false });
    }
  };

  const visibleChargingStations = filterWithinBounds(chargingStations).filter(
    (station) =>
      isIgnoringLowPowerStations ? isSuperChargingStation(station) : true
  );
  const filteredChargingStations = chargingStationsFilterFn
    ? visibleChargingStations.filter(chargingStationsFilterFn)
    : visibleChargingStations;

  const handleStationSelect = (station) => {
    setHoveredStation(station);
  };

  return (
    <div className="row TravelRouteMap">
      <div className="col-lg-3">
        <MapControlPanel
          selectedStation={selectedStation}
          chargingStations={filteredChargingStations}
          showStationsByPowerLevel={showStationsByPowerLevel}
          highPowerLegendTitle={highPowerLegendTitle}
          highPowerLegendLabel={highPowerLegendLabel}
          otherTypesLegendTitle={otherTypesLegendTitle}
          otherTypesLegendLabel={otherTypesLegendLabel}
          publicLegendTitle={publicLegendTitle}
          publicLegendLabel={publicLegendLabel}
          publicIcon={publicIcon}
          highPowerIcon={highPowerIcon}
          defaultIcon={defaultIcon}
          alwaysShowLegend
        >
          {controlPanelTitle && <p className="title">{controlPanelTitle}</p>}
          <div className="form-group">
            <label
              className="label-subTitle"
              htmlFor="travel-route-map-start-location"
            >
              Start Location
            </label>

            <div className="travel-route-map-input-wrapper">
              <img
                className="travel-route-map-icon"
                alt="Kia Motors Start Icon"
                src={StartIcon}
              />

              <input
                id="travel-route-map-start-location"
                type="text"
                className="form-control"
                onChange={(e) => setStartAddress(e.target.value)}
                value={startAddress}
                placeholder={startInputPlaceholder || "Start Location"}
                disabled={isFetchingRoute}
                onKeyPress={handleKeyPress}
                onClick={() => setShowRealMap(true)}
              />
            </div>
          </div>
          <div className="form-group">
            <label
              className="label-subTitle"
              htmlFor="travel-route-map-end-location"
            >
              End Location
            </label>

            <div className="travel-route-map-input-wrapper">
              <img
                className="travel-route-map-icon"
                alt="Kia Motors End Icon"
                src={EndIcon}
              />
              <input
                id="travel-route-map-end-location"
                type="text"
                className="form-control"
                onClick={() => setShowRealMap(true)}
                onChange={(e) => setDestinationAddress(e.target.value)}
                value={destinationAddress}
                placeholder={endInputPlaceholder || "End Location"}
                disabled={isFetchingRoute}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>

          {showDistanceSelector && (
            <div className="form-group">
              <label
                className="label-subTitle"
                htmlFor="travel-route-map-select-radius"
              >
                Include Charging Stations Within
              </label>
              <select
                id="travel-route-map-select-radius"
                className="form-control"
                value={distance}
                onChange={(e) => setDistance(e.target.value)}
              >
                <option value={1}>1 Mile</option>
                <option value={2}>2 Miles</option>
                <option value={5}>5 Miles</option>
              </select>
            </div>
          )}

          <div className="form-group">
            <button
              style={btnStyles}
              className="btn-kia"
              type="button"
              onClick={fetchRoute}
              disabled={!isSubmittingAllowed()}
            >
              {searchBtnlabel || "Map Route"}
            </button>
          </div>
          {canIgnoreLowPowerStations && (
            <div className="form-group">
              <Checkbox
                id="show-only-supercharging-stations-route"
                value={isIgnoringLowPowerStations}
                label="High Power Stations Only"
                handler={(e) =>
                  setIsIgnoringLowPowerStations((currentValue) => {
                    GaTracker.trackEvent({
                      category: "Maps",
                      action: "Toggled 'High Power Stations Only'",
                      label: currentValue ? "All Stations" : "High Power Only",
                    });
                    return !currentValue;
                  })
                }
              />
            </div>
          )}
          {chargingStationsError && (
            <p className="text-danger">
              There was an error fetching charging stations. Please try again.
            </p>
          )}
          {routeError && (
            <p className="text-danger">
              We could not find that route. Please check the addresses and try
              again.
            </p>
          )}
        </MapControlPanel>
      </div>
      <div className="col-lg-9">
        <div className="map-container">
          <MapLegend>
            {routeDistanceInMeters && (
              <div>
                This route is {Math.ceil(routeDistanceInMeters / 1609)} miles
              </div>
            )}
            {pspsEvents.length > 0 && <PspsEventLegend />}
          </MapLegend>
          {isVisible && (
            <BaseGoogleMapReact
              onGoogleApiLoaded={saveMapRefs}
              onHoverOnStation={handleStationSelect}
              onClickMarker={selectStation}
              chargingStations={filteredChargingStations}
              selectedStation={selectedStation}
              showStationsByPowerLevel={showStationsByPowerLevel}
              publicIcon={publicIcon}
              highPowerIcon={highPowerIcon}
              defaultIcon={defaultIcon}
              publicIconSelected={publicIconSelected}
              highPowerIconSelected={highPowerIconSelected}
              defaultIconSelected={defaultIconSelected}
              customizeMarkOnHover={customizeMarkOnHover}
              hoveredStation={hoveredStation}
              showRealMap={showRealMap}
              onInteraction={() => {
                setShowRealMap(true);
              }}
            />
          )}
        </div>
      </div>

      <div className="col-lg-12">
        <ChargingStationsList
          footerText={footerText}
          btnStyle={showListBtnStyle}
          showLegendCard={showLegendCard}
          chargingStations={filteredChargingStations}
          publicIcon={publicIcon}
          highPowerIcon={highPowerIcon}
          defaultIcon={defaultIcon}
          chargingListStyle={chargingListStyle}
          chargingListCardStyle={chargingListCardStyle}
          showStationsList={showStationsList}
        />
      </div>
    </div>
  );
};

TravelRouteMap.propTypes = {
  chargingStationsFilterFn: PropTypes.func,
  isVisible: PropTypes.bool,
  canIgnoreLowPowerStations: PropTypes.bool,
  pspsEvents: PropTypes.array,
  showStationsByPowerLevel: PropTypes.bool,
  showStationsList: PropTypes.bool,
  highPowerLegendTitle: PropTypes.string,
  highPowerLegendLabel: PropTypes.string,
  otherTypesLegendTitle: PropTypes.string,
  otherTypesLegendLabel: PropTypes.string,
  publicLegendTitle: PropTypes.string,
  publicLegendLabel: PropTypes.string,
  publicIcon: PropTypes.string,
  highPowerIcon: PropTypes.string,
  defaultIcon: PropTypes.string,
  searchBtnText: PropTypes.string,
  controlPanelTitle: PropTypes.string,
  zipcodeLabel: PropTypes.string,
  customizeMarkOnHover: PropTypes.func,
  initialZoomLevel: PropTypes.number,
  btnStyles: PropTypes.object,
  showLegendCard: PropTypes.bool,
  searchBtnlabel: PropTypes.string,
};

export default TravelRouteMap;
